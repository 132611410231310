import { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  Container,
  Popper,
  Stack,
  TextField,
} from "@mui/material";
import { useAuth } from "src/hooks/use-auth";
import { withBookingSystemGuard } from "src/hocs/with-role-based-guard";
import Error404Page from "src/pages/404";
import { useTranslation } from "react-i18next";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import {
  columnsDefinition,
  dataGridStyle,
} from "src/sections/dashboard/booking-system/skipped-bookings/booking-system-skipped-bookings-table-settings";
// User Group Types
import {
  bookingSystemTomorrowCrewPermissions,
  groupTypes,
} from "src/guards/role-based-guard";
// Redux Toolkit
import { useDispatch, useSelector } from "src/store";
import { thunks } from "src/thunks/booking-system";
import { slice } from "src/slices/booking-system";
import { tokens } from "src/locales/tokens";
import {
  CustomButton,
  CustomTextField,
  CustomTypography,
} from "src/components/inputs";
import { MobileDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { ClearOutlined, CloseOutlined } from "@mui/icons-material";
import tmwWarningIcon from "src/icons/tmw-ui/tmw-warning-icon.png";

const SkippedPage = () => {
  // Redundant permission check
  const { user } = useAuth();
  const userPermissions = user?.permissions;

  const hasAccess = userPermissions?.find(
    (object) =>
      object.key === bookingSystemTomorrowCrewPermissions.admin ||
      object.key === bookingSystemTomorrowCrewPermissions.bookingSystemAdmin ||
      object.key === bookingSystemTomorrowCrewPermissions.bookingSystemUser
  );

  if (!hasAccess) {
    return <Error404Page />;
  }

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    skippedBookings,
    skippedBookingsLoading,
    newClientDropdownValues,
    newClientDropdownValuesLoading,
    salesCampaignDropdownValues,
    allLocations,
    exportLoading,
  } = useSelector((state) => state.bookingSystem);

  const { timeSlots, timezoneOffset } = useSelector(
    (state) => state.bookingProcess
  );

  const [addBookingModalVisible, setAddBookingModalVisible] = useState(false);

  // Filtering
  const [filters, setFilters] = useState({
    selectedBrand: null,
    selectedCategory: null,
    selectedSeason: null,
    selectedLocation: null,
    fromDate: null,
    toDate: null,
    selectedMeetingType: null,
    selectedSyncedWithHubspot: null,
    skippedBookingsRows: skippedBookings,
  });

  // New Booking Values
  const [newClient, setNewClient] = useState(null);
  const [clientHashedId, setClientHashedId] = useState(null);
  const [newCampaign, setNewCampaign] = useState(null);
  const [campaignHashedId, setCampaignHashedId] = useState(null);
  const [hoveredBookingPageButton, setHoveredBookingPageButton] =
    useState(false);

  const [modalOpened, setModalOpened] = useState(false);
  const [deleteAppointmentModalId, setDeleteAppointmentModalId] = useState("");
  const [hoveredDeleteBookingButton, setHoveredDeleteBookingButton] =
    useState(false);

  // User Group
  const userGroup = user.group?.key;
  const userId = user.hashedId;

  useEffect(() => {
    dispatch(thunks.getSkippedBookings(userId));
    dispatch(thunks.getAllLocations(userId));
  }, []);

  useEffect(() => {
    //
    // Filters
    //

    // Brand
    const savedBrandFilters = JSON.parse(
      localStorage.getItem("skippedBookingBrandFilters")
    );

    const savedCategoryFilters = JSON.parse(
      localStorage.getItem("skippedBookingCategoryFilters")
    );

    const savedSeasonFilters = JSON.parse(
      localStorage.getItem("skippedBookingSeasonFilters")
    );

    const savedLocationFilters = JSON.parse(
      localStorage.getItem("skippedBookingLocationFilters")
    );

    const savedFromDateFilters = JSON.parse(
      localStorage.getItem("skippedBookingFromDateFilters")
    );

    const savedToDateFilters = JSON.parse(
      localStorage.getItem("skippedBookingToDateFilters")
    );

    handleMultipleFiltersChange({
      selectedBrand: savedBrandFilters,
      selectedCategory: savedCategoryFilters,
      selectedSeason: savedSeasonFilters,
      selectedLocation: savedLocationFilters,
      fromDate: savedFromDateFilters,
      toDate: savedToDateFilters,
    });
  }, [skippedBookings]);

  useEffect(() => {
    // Brand
    localStorage.setItem(
      "skippedBookingBrandFilters",
      JSON.stringify(filters.selectedBrand)
    );

    // Category
    localStorage.setItem(
      "skippedBookingCategoryFilters",
      JSON.stringify(filters.selectedCategory)
    );

    // Season
    localStorage.setItem(
      "skippedBookingSeasonFilters",
      JSON.stringify(filters.selectedSeason)
    );

    // Location
    localStorage.setItem(
      "skippedBookingLocationFilters",
      JSON.stringify(filters.selectedLocation)
    );

    // From Date
    localStorage.setItem(
      "skippedBookingFromDateFilters",
      JSON.stringify(filters.fromDate)
    );

    // To Date
    localStorage.setItem(
      "skippedBookingToDateFilters",
      JSON.stringify(filters.toDate)
    );
  }, [filters]);

  // Debounce Client List Data
  useEffect(() => {
    let controller;

    if (newClient) {
      controller = new AbortController();

      const delayInputTimeoutId = setTimeout(() => {
        dispatch(
          thunks.getClientAndBuyingGroupData(
            userId,
            newClient,
            controller.signal
          )
        );
      }, 500);

      // Cleanup: clear the timeout and abort the request if necessary
      return () => {
        clearTimeout(delayInputTimeoutId); // Clear the previous timeout
        if (controller) {
          controller.abort(); // Abort the request if `newClient` changes before the request completes
        }
      };
    } else {
      dispatch(slice.actions.setNewClientDropdownValues([]));
    }
  }, [newClient]);

  // Debounce Client List Data
  useEffect(() => {
    if (newCampaign) {
      const delayInputTimeoutId = setTimeout(() => {
        dispatch(thunks.getSalesCampaignData(userId, newCampaign));
      }, 500);
      return () => clearTimeout(delayInputTimeoutId);
    } else {
      dispatch(slice.actions.setSalesCampaignDropdownValues([]));
    }
  }, [newCampaign]);

  const applyFilters = (filters) => {
    const skippedBookingsRows = skippedBookings.filter((skippedBooking) => {
      return (
        (!filters.selectedBrand ||
          skippedBooking.brand_name === filters.selectedBrand) &&
        (!filters.selectedCategory ||
          skippedBooking.category === filters.selectedCategory) &&
        (!filters.selectedSeason ||
          skippedBooking.season === filters.selectedSeason) &&
        (!filters.selectedLocation ||
          skippedBooking.location === filters.selectedLocation) &&
        (!filters.selectedMeetingType ||
          skippedBooking.meetingType === filters.selectedMeetingType) &&
        (!filters.selectedSyncedWithHubspot ||
          skippedBooking.syncedWithHubspot ===
            (filters.selectedSyncedWithHubspot === "Yes")) &&
        (!filters.fromDate ||
          moment(filters.fromDate).format("YYYY/MM/DD") <=
            moment(skippedBooking.createdAt).format("YYYY/MM/DD")) &&
        (!filters.toDate ||
          moment(filters.toDate).format("YYYY/MM/DD") >=
            moment(skippedBooking.createdAt).format("YYYY/MM/DD"))
      );
    });

    setFilters((prevState) => ({
      ...prevState,
      ...filters,
      skippedBookingsRows,
    }));
  };

  const handleFilterChange = (filterName, value) => {
    applyFilters({
      ...filters,
      [filterName]: value,
    });
  };

  // This function is used for the localStorage values retrieval
  const handleMultipleFiltersChange = (currentFilters) => {
    const filterObject = {
      ...filters,
    };

    for (let [key, value] of Object.entries(currentFilters)) {
      filterObject[key] = value;
    }

    applyFilters(filterObject);
  };

  const renderCircularProgress = () => {
    return (
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 1100,
          color: "black",
        }}
      >
        <CircularProgress color="inherit" size={35} />
      </Box>
    );
  };

  const tableColumnsDefinition = columnsDefinition(
    userId,
    groupTypes,
    userGroup,
    modalOpened,
    setModalOpened,
    skippedBookings,
    allLocations,
    timeSlots,
    setDeleteAppointmentModalId,
    filters,
    setFilters
  );

  //
  // Components
  //

  const renderBrandAutoselect = () => {
    const brandAutocompleteData = [
      ...new Set(
        skippedBookings?.reduce((acc, label) => {
          acc.push(label.brand_name);
          return acc;
        }, [])
      ),
    ]
      .filter(Boolean)
      .sort();

    return (
      <Stack
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          margin: "30px 0 -15px 0",
        }}
      >
        <span
          style={{
            fontFamily: "tmwCustomFont",
            fontSize: 11,
            color: "black",
            letterSpacing: 3,
            fontWeight: 500,
            userSelect: "none",
            textTransform: "uppercase",
            marginLeft: "10px",
          }}
        >
          Brand
        </span>
        <Autocomplete
          id="autocomplete-brand"
          name="autocomplete-brand"
          options={brandAutocompleteData}
          value={filters.selectedBrand}
          onChange={(e, value) => {
            handleFilterChange("selectedBrand", value);
          }}
          renderOption={(props, option) => (
            <li {...props} key={option}>
              <div
                style={{
                  fontFamily: "tmwCustomFont",
                  letterSpacing: 2,
                  fontSize: 11,
                }}
              >
                {option}
              </div>
            </li>
          )}
          renderInput={(params) => (
            <>
              <TextField
                {...params}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottom: "1px solid #E6E6E6",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottom: "2px solid black",
                  },
                  "& .css-dk8gfa-MuiSvgIcon-root-MuiSelect-icon": {
                    cursor: "pointer",
                  },
                  "& .MuiInputBase-input": {
                    fontFamily: "tmwCustomFont",
                    paddingLeft: "10px",
                  },
                  padding: "10px 0",
                  width: "150px",
                }}
                id="selectedBrand"
                name="selectedBrand"
                fullWidth
                variant="standard"
              />
            </>
          )}
        />
      </Stack>
    );
  };

  const renderCategoryAutoselect = () => {
    const categoryAutocompleteData = [
      ...new Set(skippedBookings?.flatMap((booking) => booking.category)),
    ].filter(Boolean);

    return (
      <Stack
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          margin: "30px 0 -15px 0",
        }}
      >
        <span
          style={{
            fontFamily: "tmwCustomFont",
            fontSize: 11,
            color: "black",
            letterSpacing: 3,
            fontWeight: 500,
            userSelect: "none",
            textTransform: "uppercase",
            marginLeft: "10px",
          }}
        >
          Category
        </span>
        <Autocomplete
          id="autocomplete-category"
          name="autocomplete-category"
          options={categoryAutocompleteData}
          value={filters.selectedCategory}
          onChange={(e, value) => handleFilterChange("selectedCategory", value)}
          renderOption={(props, option) => (
            <li {...props} key={option}>
              <div
                style={{
                  fontFamily: "tmwCustomFont",
                  letterSpacing: 2,
                  fontSize: 11,
                }}
              >
                {option}
              </div>
            </li>
          )}
          renderInput={(params) => (
            <>
              <TextField
                {...params}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottom: "1px solid #E6E6E6",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottom: "2px solid black",
                  },
                  "& .css-dk8gfa-MuiSvgIcon-root-MuiSelect-icon": {
                    cursor: "pointer",
                  },
                  "& .MuiInputBase-input": {
                    fontFamily: "tmwCustomFont",
                    paddingLeft: "10px",
                  },
                  padding: "10px 0",
                  width: "150px",
                }}
                id="selectedCategory"
                name="selectedCategory"
                fullWidth
                variant="standard"
              />
            </>
          )}
        />
      </Stack>
    );
  };

  const renderSeasonAutoselect = () => {
    const seasonAutocompleteData = [
      ...new Set(skippedBookings?.map((booking) => booking.season)),
    ].filter(Boolean);

    return (
      <Stack
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          margin: "30px 0 -15px 0",
        }}
      >
        <span
          style={{
            fontFamily: "tmwCustomFont",
            fontSize: 11,
            color: "black",
            letterSpacing: 3,
            fontWeight: 500,
            userSelect: "none",
            textTransform: "uppercase",
            marginLeft: "10px",
          }}
        >
          Season
        </span>
        <Autocomplete
          id="autocomplete-season"
          name="autocomplete-season"
          options={seasonAutocompleteData}
          value={filters.selectedSeason}
          onChange={(e, value) => handleFilterChange("selectedSeason", value)}
          renderOption={(props, option) => (
            <li {...props} key={option}>
              <div
                style={{
                  fontFamily: "tmwCustomFont",
                  letterSpacing: 2,
                  fontSize: 11,
                }}
              >
                {option}
              </div>
            </li>
          )}
          renderInput={(params) => (
            <>
              <TextField
                {...params}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottom: "1px solid #E6E6E6",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottom: "2px solid black",
                  },
                  "& .css-dk8gfa-MuiSvgIcon-root-MuiSelect-icon": {
                    cursor: "pointer",
                  },
                  "& .MuiInputBase-input": {
                    fontFamily: "tmwCustomFont",
                    paddingLeft: "10px",
                  },
                  padding: "10px 0",
                  minWidth: "150px",
                }}
                id="selectedSeason"
                name="selectedSeason"
                fullWidth
                variant="standard"
              />
            </>
          )}
        />
      </Stack>
    );
  };

  const renderLocationAutoselect = () => {
    const locationAutocompleteData = [
      ...new Set(skippedBookings?.map((booking) => booking.location)),
    ].filter(Boolean);

    return (
      <Stack
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          margin: "30px 0 -15px 0",
        }}
      >
        <span
          style={{
            fontFamily: "tmwCustomFont",
            fontSize: 11,
            color: "black",
            letterSpacing: 3,
            fontWeight: 500,
            userSelect: "none",
            textTransform: "uppercase",
            marginLeft: "10px",
          }}
        >
          Location
        </span>
        <Autocomplete
          id="autocomplete-location"
          name="autocomplete-location"
          options={locationAutocompleteData}
          value={filters.selectedLocation}
          onChange={(e, value) => handleFilterChange("selectedLocation", value)}
          renderOption={(props, option) => (
            <li {...props} key={option}>
              <div
                style={{
                  fontFamily: "tmwCustomFont",
                  letterSpacing: 2,
                  fontSize: 11,
                }}
              >
                {option}
              </div>
            </li>
          )}
          renderInput={(params) => (
            <>
              <TextField
                {...params}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottom: "1px solid #E6E6E6",
                    zIndex: 1000,
                  },
                  "& .MuiInput-underline:after": {
                    borderBottom: "2px solid black",
                  },
                  "& .css-dk8gfa-MuiSvgIcon-root-MuiSelect-icon": {
                    cursor: "pointer",
                  },
                  "& .MuiInputBase-input": {
                    fontFamily: "tmwCustomFont",
                    paddingLeft: "10px",
                  },
                  "& .MuiButtonBase-root, MuiChip-root, MuiChip-filled": {
                    background: "white",
                    height: "20px",
                    borderRadius: 0,
                    fontFamily: "tmwCustomFont",
                  },
                  padding: "10px 0",
                  minWidth: "175px",
                }}
                id="selectedLocation"
                name="selectedLocation"
                fullWidth
                variant="standard"
              />
            </>
          )}
        />
      </Stack>
    );
  };

  const renderFromDateAutoselect = () => {
    return (
      <Stack
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          margin: "30px 0 -15px 0",
        }}
      >
        <span
          style={{
            fontFamily: "tmwCustomFont",
            fontSize: 11,
            color: "black",
            letterSpacing: 3,
            fontWeight: 500,
            userSelect: "none",
            textTransform: "uppercase",
            marginLeft: "10px",
          }}
        >
          From Date
        </span>
        <MobileDatePicker
          inputFormat="dd/MM/yyyy"
          onChange={(date) => {
            handleFilterChange("fromDate", date);
          }}
          renderInput={(inputProps) => (
            <>
              <CustomTextField
                style={{ width: "150px" }}
                // label={t(tokens.linesheets.table.windowStart)}w
                // onKeyDown={onKeyDown}
                inputProps={inputProps}
                calendar
                clearable={filters.fromDate}
                clearableClick={() => {
                  handleFilterChange("fromDate", null);
                }}
                // touched={formik.touched.inboundDelivery?.from}
                // errors={formik.errors.inboundDelivery?.from}
              />
            </>
          )}
          value={filters.fromDate}
          // maxDate={formik.values.inboundDelivery.to}
        />
      </Stack>
    );
  };

  const renderToDateAutoselect = () => {
    return (
      <Stack
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          margin: "30px -25px -15px 0",
        }}
      >
        <span
          style={{
            fontFamily: "tmwCustomFont",
            fontSize: 11,
            color: "black",
            letterSpacing: 3,
            fontWeight: 500,
            userSelect: "none",
            textTransform: "uppercase",
            marginLeft: "10px",
          }}
        >
          To Date
        </span>
        <MobileDatePicker
          inputFormat="dd/MM/yyyy"
          onChange={(date) => {
            handleFilterChange("toDate", date);
          }}
          renderInput={(inputProps) => (
            <>
              <CustomTextField
                style={{ width: "150px" }}
                // label={t(tokens.linesheets.table.windowStart)}w
                // onKeyDown={onKeyDown}
                inputProps={inputProps}
                calendar
                clearable={filters.toDate}
                clearableClick={() => {
                  handleFilterChange("toDate", null);
                }}
                // touched={formik.touched.inboundDelivery?.from}
                // errors={formik.errors.inboundDelivery?.from}
              />
            </>
          )}
          value={filters.toDate}
          // maxDate={formik.values.inboundDelivery.to}
        />
      </Stack>
    );
  };

  const renderResetFiltersButton = () => {
    const [hoveredButton, setHoveredButton] = useState(false);

    const disableButton =
      !filters.selectedBrand &&
      !filters.selectedCategory &&
      !filters.selectedSeason &&
      !filters.selectedLocation &&
      !filters.fromDate &&
      !filters.toDate &&
      !filters.selectedMeetingType &&
      !filters.selectedSyncedWithHubspot;

    const handleResetFilters = () => {
      handleMultipleFiltersChange({
        selectedBrand: null,
        selectedCategory: null,
        selectedSeason: null,
        selectedLocation: null,
        fromDate: null,
        toDate: null,
        selectedMeetingType: null,
        selectedSyncedWithHubspot: null,
      });
    };

    return (
      <Button
        onMouseEnter={() => setHoveredButton(true)}
        onMouseLeave={() => setHoveredButton(false)}
        style={{
          fontFamily: "tmwCustomFont",
          textTransform: "uppercase",
          borderRadius: "25px",
          border: disableButton ? "1px solid #E6E6E6" : "1px solid red",
          transition: "ease-out 0.15s",
          letterSpacing: 2,
          fontSize: 11,
          userSelect: "none",
          fontWeight: 400,
          color: disableButton ? "#BEBEBE" : hoveredButton ? "white" : "red",
          background: disableButton ? "white" : hoveredButton ? "red" : "white",
        }}
        onClick={() => handleResetFilters()}
        disabled={disableButton}
        disableTouchRipple
      >
        Reset filters
      </Button>
    );
  };

  const renderDeleteBookingButton = () => {
    return (
      <Button
        onMouseEnter={() => setHoveredDeleteBookingButton(true)}
        onMouseLeave={() => setHoveredDeleteBookingButton(false)}
        style={{
          fontFamily: "tmwCustomFont",
          textTransform: "uppercase",
          borderRadius: "25px",
          border: "1px solid red",
          transition: "ease-out 0.15s",
          letterSpacing: 2,
          fontSize: 11,
          userSelect: "none",
          fontWeight: 400,
          color: hoveredDeleteBookingButton ? "white" : "red",
          background: hoveredDeleteBookingButton ? "red" : "white",
        }}
        onClick={() => {
          dispatch(
            thunks.deleteSkippedBooking(userId, deleteAppointmentModalId)
          );
          setDeleteAppointmentModalId("");
        }}
      >
        Delete
      </Button>
    );
  };

  const renderExportButton = () => {
    const [hoveredButton, setHoveredButton] = useState(false);
    const disabled = exportLoading || filters.skippedBookingsRows.length === 0;

    return (
      <Button
        onMouseEnter={() => setHoveredButton(true)}
        onMouseLeave={() => setHoveredButton(false)}
        style={{
          fontFamily: "tmwCustomFont",
          textTransform: "uppercase",
          borderRadius: "25px",
          border: !disabled ? "1px solid green" : "1px solid #E6E6E6",
          transition: "ease-out 0.15s",
          letterSpacing: 2,
          fontSize: 11,
          userSelect: "none",
          fontWeight: 400,
          color: !disabled ? (hoveredButton ? "white" : "green") : "#BEBEBE",
          background: hoveredButton ? "green" : "white",
        }}
        onClick={() => {
          dispatch(thunks.exportSkippedBookingsToExcel(userId, filters));
        }}
        disabled={disabled}
        disableTouchRipple
      >
        Export
        {exportLoading && (
          <CircularProgress
            size={20}
            color="inherit"
            style={{ marginLeft: "5px" }}
          />
        )}
      </Button>
    );
  };

  const renderQuickSearch = () => {
    return (
      <Stack
        style={{
          flexDirection: "row",
          width: "200px",
        }}
      >
        <GridToolbarQuickFilter
          id="custom-searchbar"
          debounceMs={250}
          sx={{
            "& .MuiInput-root:before": {
              borderBottom: "none !important",
            },
            "& .MuiInput-root:after": {
              borderBottom: "none !important",
            },
            "& .MuiInput-underline:before": {
              borderBottom: "none !important",
            },
            "& .MuiSvgIcon-root": {
              cursor: "pointer",
            },
            "& .MuiInputBase-root-MuiInput-root": {
              cursor: "pointer",
            },
            "& .MuiInput-input": {
              fontFamily: "tmwCustomFont",
              paddingBottom: "2px",
              fontSize: 11,
            },
            "& .MuiInput-input::placeholder": {
              color: "#BEBEBE",
            },
            "& .MuiInput-input::-ms-input-placeholder": {
              color: "black",
            },
            overflow: "hidden",
          }}
          style={{
            fontFamily: "tmwCustomFont",
            borderRadius: "25px",
            border: "1px solid #E6E6E6",
            padding: "5px 10px",
            letterSpacing: 2,
            width: "200px",
            cursor: "pointer",
            transition: "all .2s ease-in-out",
          }}
          size="small"
          placeholder={"SEARCH"}
        />
      </Stack>
    );
  };

  const renderFiltersContainer = () => {
    return (
      <Stack
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: 50,
          margin: "-15px 0 15px 15px",
          width: "100%",
        }}
      >
        {renderBrandAutoselect()}
        {renderCategoryAutoselect()}
        {renderSeasonAutoselect()}
        {renderLocationAutoselect()}
        {renderFromDateAutoselect()}
        {renderToDateAutoselect()}
      </Stack>
    );
  };

  const renderSecondaryButtons = () => {
    return (
      <Stack
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {/* LEFT Section */}
        <Stack style={{ flexDirection: "row", gap: 10 }}>
          {renderQuickSearch()}
        </Stack>

        {/* RIGHT Section */}
        <Stack style={{ flexDirection: "row", gap: 10 }}>
          {renderResetFiltersButton()}
          {renderExportButton()}
        </Stack>
      </Stack>
    );
  };

  // Custom toolbar for Table Header
  const customToolbar = () => {
    return (
      <GridToolbarContainer
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "0 -15px -15px 0",
        }}
      >
        <Stack
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <Stack
            style={{
              width: "100%",
              flexDirection: "row",
              justifyContent: "space-between",
              overflowY: "auto",
              scrollbarWidth: "thin",
              scrollbarColor: "lightgrey transparent",
            }}
          >
            {renderFiltersContainer()}
          </Stack>
          <Stack
            style={{
              display: "flex",
              justifyContent: "space-around",
              minHeight: "100px",
              gap: 10,
              margin: "-15px 0 5px 0",
            }}
          >
            {renderSecondaryButtons()}
          </Stack>
        </Stack>
      </GridToolbarContainer>
    );
  };

  const renderAddClientField = () => {
    return (
      <Stack
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <span
          style={{
            fontFamily: "tmwCustomFont",
            fontSize: 11,
            color: "black",
            letterSpacing: 3,
            fontWeight: 500,
            userSelect: "none",
            textTransform: "uppercase",
          }}
        >
          Client
        </span>
        <Autocomplete
          id="autocomplete-syncedWithHubspot"
          name="autocomplete-syncedWithHubspot"
          // clearIcon={<ClearOutlined onClick={() => setNewClient(null)} />}
          options={newClientDropdownValues}
          filterOptions={(x) => x}
          getOptionLabel={(option) =>
            // Avoid displaying '[object Object]' when nothing is selected
            typeof option === "string" ? option : option.email
          }
          isOptionEqualToValue={(option, value) => option.email === value.email}
          renderOption={(props, option) => (
            <li {...props} key={option.email}>
              <div
                style={{
                  fontFamily: "tmwCustomFont",
                  letterSpacing: 2,
                  fontSize: 11,
                  gap: 5,
                }}
              >
                <Stack>{option.email}</Stack>
                <Stack style={{ fontWeight: 600 }}>
                  {option.buyingGroupName}
                </Stack>
              </div>
            </li>
          )}
          value={newClient} // Bind the current value
          onChange={(event, client) => {
            setClientHashedId(client?.hashedId);
            setNewClient(client?.email || "");
          }}
          PopperComponent={({ style, ...props }) => {
            return <Popper {...props} style={{ ...style, height: 0 }} />;
          }}
          ListboxProps={{
            style: {
              fontFamily: "tmwCustomFont",
              fontSize: 13,
              borderRadius: 0,
              border: "none",
              boxShadow: "none",
              width: "fit-content",
            },
          }}
          renderInput={(params) => (
            <Stack
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <TextField
                {...params}
                value={newClient}
                onChange={(e) => {
                  setNewClient(e.target.value);
                  setClientHashedId(null);
                }}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottom: "1px solid #E6E6E6",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottom: "2px solid black",
                  },
                  "& .css-dk8gfa-MuiSvgIcon-root-MuiSelect-icon": {
                    cursor: "pointer",
                  },
                  "& .MuiInputBase-input": {
                    fontFamily: "tmwCustomFont",
                    paddingLeft: "10px",
                  },
                  padding: "10px 0",
                  width: "400px",
                }}
                id="selectedSyncedWithHubspot"
                name="selectedSyncedWithHubspot"
                fullWidth
                variant="standard"
              />
              {newClientDropdownValuesLoading && (
                <CircularProgress
                  style={{ position: "absolute", right: -40, top: 15 }}
                  color="inherit"
                  size={20}
                />
              )}
            </Stack>
          )}
        />
      </Stack>
    );
  };

  const renderAddCampaignField = () => {
    return (
      <Stack
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <span
          style={{
            fontFamily: "tmwCustomFont",
            fontSize: 11,
            color: "black",
            letterSpacing: 3,
            fontWeight: 500,
            userSelect: "none",
            textTransform: "uppercase",
          }}
        >
          Sales Campaign
        </span>
        <Autocomplete
          id="autocomplete-syncedWithHubspot"
          name="autocomplete-syncedWithHubspot"
          clearIcon={<ClearOutlined onClick={() => setNewCampaign(null)} />}
          options={salesCampaignDropdownValues}
          getOptionLabel={(option) =>
            // Avoid displaying '[object Object]' when nothing is selected
            typeof option === "string" ? option : option.sales_campaign
          }
          isOptionEqualToValue={(option, value) =>
            option.sales_campaign === value.sales_campaign
          }
          renderOption={(props, option) => (
            <li {...props} key={option.sales_campaign}>
              <div
                style={{
                  fontFamily: "tmwCustomFont",
                  letterSpacing: 2,
                  fontSize: 11,
                  gap: 5,
                }}
              >
                <Stack>{option.sales_campaign}</Stack>
              </div>
            </li>
          )}
          value={newCampaign} // Bind the current value
          onChange={(event, client) => {
            setCampaignHashedId(client?.tp__sales_campaign_id || "");
            setNewCampaign(client?.sales_campaign || "");
          }}
          PopperComponent={({ style, ...props }) => (
            <Popper {...props} style={{ ...style, height: 0 }} />
          )}
          ListboxProps={{
            style: {
              fontFamily: "tmwCustomFont",
              fontSize: 13,
              borderRadius: 0,
              border: "none",
              boxShadow: "none",
              width: "fit-content",
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              value={newCampaign}
              onChange={(e) => {
                setNewCampaign(e.target.value);
                setCampaignHashedId(null);
              }}
              sx={{
                "& .MuiInput-underline:before": {
                  borderBottom: "1px solid #E6E6E6",
                },
                "& .MuiInput-underline:after": {
                  borderBottom: "2px solid black",
                },
                "& .css-dk8gfa-MuiSvgIcon-root-MuiSelect-icon": {
                  cursor: "pointer",
                },
                "& .MuiInputBase-input": {
                  fontFamily: "tmwCustomFont",
                  paddingLeft: "10px",
                },
                padding: "10px 0",
                width: "400px",
              }}
              id="selectedSyncedWithHubspot"
              name="selectedSyncedWithHubspot"
              fullWidth
              variant="standard"
            />
          )}
        />
      </Stack>
    );
  };

  const handleCancelAddNewBooking = () => {
    setNewClient(null);
    setClientHashedId(null);
    setNewCampaign(null);
    setCampaignHashedId(null);
    setAddBookingModalVisible(false);
  };

  const renderBookingPageButton = () => {
    const disabledButton = !clientHashedId || !campaignHashedId;

    return (
      <Button
        onMouseEnter={() => setHoveredBookingPageButton(true)}
        onMouseLeave={() => setHoveredBookingPageButton(false)}
        onClick={() => {
          window
            .open(
              `/booking-process/${campaignHashedId}/${clientHashedId}/${userId}`,
              "_blank"
            )
            .focus();
          handleCancelAddNewBooking();
        }}
        disabled={disabledButton}
        style={{
          fontFamily: "tmwCustomFont",
          textTransform: "uppercase",
          borderRadius: "25px",
          border: disabledButton ? "1px solid #E6E6E6" : "1px solid green",
          transition: "ease-out 0.15s",
          letterSpacing: 2,
          fontSize: 11,
          userSelect: "none",
          fontWeight: 400,
          color: disabledButton
            ? "#BEBEBE"
            : hoveredBookingPageButton
            ? "white"
            : "green",
          background: disabledButton
            ? "white"
            : hoveredBookingPageButton
            ? "green"
            : "white",
        }}
      >
        Go to Booking Page
      </Button>
    );
  };

  const renderAddBookingModal = () => {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100vh",
          backdropFilter: "blur(5px)",
          zIndex: 1100,
        }}
      >
        <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1100,
            minWidth: "700px",
          }}
        >
          <Container>
            <Stack
              elevation={12}
              style={{
                padding: "25px 25px 50px 25px",
                background: "white",
                border: "1px solid #E6E6E6",
              }}
            >
              <Stack
                style={{
                  width: "100%",
                  alignItems: "flex-end",
                  margin: "-10px 0 25px 5px",
                }}
              >
                <ButtonBase
                  style={{ color: "black", width: "10px" }}
                  onClick={() => setAddBookingModalVisible(false)}
                >
                  <CloseOutlined style={{ fontSize: 20 }} />
                </ButtonBase>
              </Stack>
              <Stack style={{ gap: 35 }}>
                <Stack
                  style={{ margin: "-15px 0 15px 0", alignItems: "center" }}
                >
                  <CustomTypography
                    title
                    message="Please select the client and sales campaign"
                  />
                </Stack>
                <Stack style={{ alignItems: "center", gap: 15 }}>
                  {renderAddClientField()}
                  {renderAddCampaignField()}
                </Stack>
              </Stack>
              <Stack
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  gap: 10,
                  margin: "35px 0 -15px 0",
                }}
              >
                {renderBookingPageButton()}
                <CustomButton
                  label="Cancel"
                  onClick={() => handleCancelAddNewBooking()}
                />
              </Stack>
            </Stack>
          </Container>
        </Box>
      </div>
    );
  };

  const renderDeleteAppointmentModal = () => {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backdropFilter: "blur(5px)",
          zIndex: 1100,
        }}
      >
        <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1100,
          }}
        >
          <Container>
            <Stack
              elevation={12}
              style={{
                padding: "25px 25px 50px 25px",
                background: "white",
                border: "1px solid #E6E6E6",
              }}
            >
              <Stack
                style={{
                  padding: "15px 15px 0 15px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 15,
                }}
              >
                <img
                  style={{ width: "40px", height: "40px" }}
                  src={tmwWarningIcon}
                />
                <CustomTypography message="Are you sure you want to delete this skipped booking?" />
              </Stack>
              <Stack
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  gap: 25,
                  margin: "20px 0 -15px 0",
                }}
              >
                {renderDeleteBookingButton()}
                <CustomButton
                  label="Cancel"
                  onClick={() => setDeleteAppointmentModalId("")}
                />
              </Stack>
            </Stack>
          </Container>
        </Box>
      </div>
    );
  };

  const renderTable = () => {
    return (
      <>
        {!skippedBookingsLoading ? (
          <Container
            maxWidth={false}
            sx={{
              maxHeight: "90vh",
              width: "100%",
              scrollbarWidth: "thin",
              scrollbarColor: "lightgrey transparent",
            }}
          >
            <DataGridPro
              disableColumnFilter
              disableDensitySelector
              sx={dataGridStyle}
              rows={filters.skippedBookingsRows}
              columns={tableColumnsDefinition}
              pagination
              slots={{
                toolbar: customToolbar,
              }}
              onCellEditStart={() => setModalOpened(true)}
              disableColumnSelector
              disableRowSelectionOnClick
              getRowHeight={() => "auto"}
              slotProps={{
                toolbar: {
                  style: {
                    marginTop: "-75px",
                    marginBottom: "25px",
                    marginLeft: 0,
                    display: "flex",
                    flexDirection: "row-reverse",
                    width: "25%",
                  },
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                },
                pagination: {
                  SelectProps: {
                    MenuProps: {
                      sx: {
                        "& .MuiMenuItem-root": {
                          textTransform: "uppercase",
                          fontSize: 11,
                          letterSpacing: 1,
                        },
                      },
                    },
                  },
                },
              }}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 25, page: 0 },
                },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
            />
          </Container>
        ) : (
          renderCircularProgress()
        )}
      </>
    );
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 2,
        px: 3,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {renderTable()}
      {addBookingModalVisible && renderAddBookingModal()}
      {deleteAppointmentModalId && renderDeleteAppointmentModal()}
    </Box>
  );
};

export default withBookingSystemGuard(SkippedPage);
