import axios from "axios";

const deployEnvironment = require("../../deployEnvironment.js");
const API = deployEnvironment;
const STORAGE_KEY = "accessToken";

class BookingSystemAPI {
  async getAllBookings(userId) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const response = await axios.post(
      `${API}/api/booking-system/getAllBookings`,
      { userId },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const allBookings = response.data.allBookings;

    return new Promise((resolve, reject) => {
      try {
        if (!allBookings) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(allBookings);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }
  async getClientAndBuyingGroupData(userId, searchField, signal) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const response = await axios.post(
      `${API}/api/booking-system/getClientAndBuyingGroupData`,
      { userId, searchField },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        signal: signal,
      }
    );

    const clientAndBuyingGroupData = response.data.clientAndBuyingGroupData;

    return new Promise((resolve, reject) => {
      try {
        if (!clientAndBuyingGroupData) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(clientAndBuyingGroupData);
      } catch (err) {
        if (err.name === "AbortError") {
          console.log("Request was aborted");
          // Optionally, handle abort-specific behavior here, like resetting loading state
        } else {
          reject(new Error("Internal server error"));
        }
      }
    });
  }
  async getSalesCampaignData(userId, salesCampaign) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const response = await axios.post(
      `${API}/api/booking-system/getSalesCampaignData`,
      { userId, salesCampaign },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const salesCampaignData = response.data.salesCampaignData;

    return new Promise((resolve, reject) => {
      try {
        if (!salesCampaignData) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(salesCampaignData);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }
  async getAllLocations(userId) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const response = await axios.post(
      `${API}/api/booking-system/getAllLocations`,
      { userId },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const allLocations = response.data.allLocations;

    return new Promise((resolve, reject) => {
      try {
        if (!allLocations) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(allLocations);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }
  async updateBooking(userId, fields, appointmentId) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    await axios.post(
      `${API}/api/booking-system/updateBooking`,
      { userId, fields, appointmentId },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }
  async deleteBooking(userId, appointmentId) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    await axios.post(
      `${API}/api/booking-system/deleteBooking`,
      { userId, appointmentId },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }
  async getBookingLinks(userId) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const response = await axios.post(
      `${API}/api/booking-system/getBookingLinks`,
      { userId },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const bookingLinks = response.data.bookingLinks;

    return new Promise((resolve, reject) => {
      try {
        if (!bookingLinks) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(bookingLinks);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }
  async setBookingLinks(userId, brandPortfolioLink, showroomFlyersLink) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    await axios.post(
      `${API}/api/booking-system/setBookingLinks`,
      { userId, brandPortfolioLink, showroomFlyersLink },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }
  async exportBookings(userId, filters) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const response = await axios.post(
      `${API}/api/booking-system/exportBookings`,
      { userId, filters },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const bookings = response.data;

    return new Promise((resolve, reject) => {
      try {
        if (!bookings) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(bookings);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }
  async getSkippedBookings(userId) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const response = await axios.post(
      `${API}/api/booking-system/getSkippedBookings`,
      { userId },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const skippedBookings = response.data.skippedBookings;

    return new Promise((resolve, reject) => {
      try {
        if (!skippedBookings) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(skippedBookings);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }
  async exportSkippedBookings(userId, filters) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const response = await axios.post(
      `${API}/api/booking-system/exportSkippedBookings`,
      { userId, filters },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const skippedBookings = response.data;

    return new Promise((resolve, reject) => {
      try {
        if (!skippedBookings) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(skippedBookings);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }
  async deleteSkippedBooking(userId, appointmentId) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    await axios.post(
      `${API}/api/booking-system/deleteSkippedBooking`,
      { userId, appointmentId },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }
}

export const bookingSystemApi = new BookingSystemAPI();
