import PropTypes from "prop-types";
import Menu01Icon from "@untitled-ui/icons-react/build/esm/Menu01";
import { Box, IconButton, Stack, SvgIcon, useMediaQuery } from "@mui/material";
import { RouterLink } from "src/components/router-link";
import { usePathname } from "src/hooks/use-pathname";
import { paths } from "src/paths";
import { AccountButton } from "../account-button";
import { NotificationsButton } from "../notifications-button";
import { HelpButton } from "../help-button";
import { DownloadTemplateButton } from "../download-template-button";
import { TenantSwitch } from "../tenant-switch";
import { TopNavSection } from "./top-nav-section";
import tmwLogo from "src/images/tmw_logo.png";
import { CustomDivider, CustomTypography } from "src/components/inputs";
import { useAuth } from "src/hooks/use-auth";
import { groupTypes } from "src/guards/role-based-guard";
import { useDispatch, useSelector } from "src/store";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { thunks } from "src/thunks/e-taf";
import { slice } from "src/slices/e-taf";

export const TopNav = (props) => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const userGroup = user?.group?.key;
  const params = useParams();
  const companyHash = params?.company_hash;

  const { onMobileNav, sections = [] } = props;
  const pathname = usePathname();
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const { currentCompanyName } = useSelector((state) => state.eTaf);

  useEffect(() => {
    if (currentCompanyName) {
      dispatch(slice.actions.setSelectedCompany(currentCompanyName));
    }
  }, [currentCompanyName]);

  return (
    <Box
      component="header"
      sx={{
        color: "var(--nav-color)",
        top: 0,
        left: 0,
        position: "sticky",
        zIndex: (theme) => theme.zIndex.appBar,
      }}
      style={{ background: "white" }}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
          px: 7,
          paddingTop: 2,
          // paddingBottom: 1,
        }}
      >
        <Stack alignItems="center" direction="row" spacing={2}>
          {!mdUp && (
            <IconButton onClick={onMobileNav}>
              <SvgIcon>
                <Menu01Icon />
              </SvgIcon>
            </IconButton>
          )}
          <Box
            component={RouterLink}
            href={paths.index}
            sx={{
              overflow: "hidden",
              display: "inline-flex",
              height: 70,
              width: 150,
            }}
          >
            <img style={{ margin: "-25% 0" }} src={tmwLogo} />
          </Box>
          <TenantSwitch />
        </Stack>
        <Stack alignItems="center" direction="row" spacing={1.5}>
          {/* <LanguageSwitch />
          <ContactsButton /> */}
          {userGroup === groupTypes.customer && (
            <CustomTypography
              message={currentCompanyName}
              style={{ fontSize: 12, marginRight: "5px" }}
            />
          )}
          {userGroup !== groupTypes.customer && <DownloadTemplateButton />}
          <HelpButton />
          <NotificationsButton />
          <AccountButton />
        </Stack>
      </Stack>
      {mdUp && (
        <Box>
          <Stack
            alignItems="center"
            component="nav"
            direction="row"
            spacing={1}
            sx={{
              px: 2,
              py: 1.5,
            }}
          >
            {sections.map((section, index) => (
              <TopNavSection
                items={section.items}
                key={index}
                pathname={pathname}
                subheader={section.subheader}
              />
            ))}
          </Stack>
        </Box>
      )}
      <Box sx={{ paddingTop: 2 }}>
        <CustomDivider />
      </Box>
    </Box>
  );
};

TopNav.propTypes = {
  color: PropTypes.oneOf(["blend-in", "discreet", "evident"]),
  onMobileNav: PropTypes.func,
  sections: PropTypes.array,
};
