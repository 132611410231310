import PropTypes from "prop-types";
import {
  Box,
  Button,
  ButtonBase,
  ButtonGroup,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import { useAuth } from "src/hooks/use-auth";
import { paths } from "src/paths";
import {
  CustomButton,
  CustomDivider,
  CustomTooltip,
  CustomTypography,
} from "src/components/inputs";
import EditIcon from "src/icons/tmw-ui/Tomorrow-Portal-Edit-Linesheets-Symbol.png";
import { groupTypes } from "src/guards/role-based-guard";
import { useDispatch, useSelector } from "src/store";
import { useSearchParams } from "src/hooks/use-search-params";
import { useParams } from "react-router-dom";

export const AccountPopover = (props) => {
  const { anchorEl, onClose, open, onLogoutModalOpen, ...other } = props;
  const { user } = useAuth();
  const searchParams = useSearchParams();
  const params = useParams();
  const returnTo = searchParams.get("returnTo");

  const { currentCompanyName, companyData } = useSelector(
    (state) => state.eTaf
  );

  const userCompanies = user?.contact?.companies?.map((company) => ({
    name: company.name,
    hash: company.hs_object_id,
    companyDataCompleted: company.companyDataCompleted,
  }));

  const tmwTextStyle = {
    letterSpacing: 1,
    fontSize: 13,
    fontWeight: 600,
  };

  const handleCompanyClick = (company) => {
    if (
      window.location.href?.split("/")[4] === "company-form" &&
      params?.company_hash === company.hash
    ) {
      window.location.href =
        returnTo || paths.customers.dashboard.index + `/${company.hash}`;
    } else if (company.name !== currentCompanyName) {
      if (!company.companyDataCompleted) {
        window.location.href =
          returnTo || paths.customers.companyForm.index + `/${company.hash}`;
      } else {
        window.location.href =
          returnTo || paths.customers.dashboard.index + `/${company.hash}`;
      }
    }
  };

  const handleCompanyEditClick = (company) => {
    window.location.href =
      returnTo || paths.customers.companyForm.index + `/${company.hash}`;
  };

  const handleAddNewCompany = () => {
    const randomHash = Array.from({ length: 16 }, () =>
      "0123456789abcdefghijklmnopqrstuvwxyz".charAt(
        Math.floor(Math.random() * 16)
      )
    ).join("");

    window.location.href =
      returnTo || paths.customers.companyForm.index + `/${randomHash}`;
  };

  return (
    <>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        disableScrollLock
        onClose={onClose}
        open={!!open}
        PaperProps={{
          sx: {
            p: 1,
            width: "fit-content",
            border: "1px solid #e6e6e6",
            borderRadius: 0,
            boxShadow: "none",
          },
        }}
        {...other}
      >
        <Box sx={{ p: 2, minWidth: 300 }} style={tmwTextStyle}>
          <CustomTypography
            message={
              user?.firstName || user?.lastName ? (
                <>
                  <span>{user?.firstName}</span>
                  <span style={{ marginLeft: "5px" }}>{user?.lastName}</span>
                </>
              ) : (
                <span>{user?.name}</span>
              )
            }
          />
          <Typography color="text.secondary" variant="body2">
            {user?.email}
          </Typography>
        </Box>
        {user?.group && user?.group?.key !== groupTypes.customer && (
          <>
            <CustomDivider small />
            <Box sx={{ p: 2 }} style={{ ...tmwTextStyle, textAlign: "center" }}>
              <CustomTypography
                style={{ fontSize: 14 }}
                message={user?.group?.groupName}
              />
              {user?.brand && (
                <Typography
                  color="text.secondary"
                  variant="body2"
                  style={{ fontFamily: "tmwCustomFont" }}
                >
                  {user?.brand?.name}
                </Typography>
              )}
            </Box>
          </>
        )}
        <CustomDivider small />
        {user?.group?.key === groupTypes.customer &&
          userCompanies?.length >= 1 && (
            <>
              <Stack
                style={tmwTextStyle}
                sx={{
                  gap: 2,
                  p: 2,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <CustomTypography
                  message={
                    userCompanies.length > 1
                      ? "Your companies:"
                      : "Your company:"
                  }
                  style={{ width: "100%" }}
                />
                <Stack style={{ width: "101%" }}>
                  {userCompanies.map((company) => {
                    return (
                      <ButtonGroup
                        key={company.name + "_company_button_group_key"}
                        fullWidth
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "5px 0",
                          borderRadius: "25px",
                        }}
                      >
                        <CustomButton
                          key={company.name + "_company_key"}
                          style={{
                            cursor: "pointer",
                            "&:hover": {
                              background: "#E6E6E6",
                              borderColor: "#E6E6E6",
                              outline: "none",
                            },
                            color: "#fff",
                          }}
                          onClick={() => handleCompanyClick(company)}
                          disabled={
                            (company.name === currentCompanyName &&
                              !(
                                window.location.href?.split("/")[4] ===
                                  "company-form" &&
                                params?.company_hash === company.hash
                              )) ||
                            (!company.companyDataCompleted &&
                              window.location.href?.split("/")[4] ===
                                "company-form" &&
                              params?.company_hash === company.hash)
                          }
                          label={company.name}
                        />
                        {company.companyDataCompleted && (
                          <CustomButton
                            key={company.name + "_company_edit_key"}
                            style={{
                              cursor: "pointer",
                              width: "20%",
                            }}
                            onClick={() => handleCompanyEditClick(company)}
                            disabled={
                              window.location.href?.split("/")[4] ===
                                "company-form" &&
                              params?.company_hash === company.hash
                            }
                            label={
                              <CustomTooltip title="Edit company data">
                                <img
                                  style={{
                                    width: "15px",
                                  }}
                                  src={EditIcon}
                                />
                              </CustomTooltip>
                            }
                          />
                        )}
                      </ButtonGroup>
                    );
                  })}
                  {/* Add new company button */}
                  {/* <CustomButton
                    label="+ Add new company"
                    onClick={handleAddNewCompany}
                    style={{ marginTop: "6px" }}
                  /> */}
                </Stack>
              </Stack>
              <CustomDivider small />
            </>
          )}
        <Stack
          sx={{
            display: "flex",
            p: 2,
            justifyContent: "center",
          }}
        >
          <CustomButton
            color="inherit"
            onClick={onLogoutModalOpen}
            size="small"
            style={{
              ...tmwTextStyle,
              border: "1px solid red",
              "&:hover": {
                border: "1px solid red",
                backgroundColor: "red",
              },
            }}
            label="Log out"
            logoutButton
          />
        </Stack>
      </Popover>
    </>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
