import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { CustomButton } from "src/components/inputs";
import { RouterLink } from "src/components/router-link";
import { Seo } from "src/components/seo";
import { usePageView } from "src/hooks/use-page-view";
import { paths } from "src/paths";

const MaintenancePage = (props) => {
  const mdUp = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const maintenanceTitle = props?.maintenanceTitle;
  const maintenanceMessage = props?.maintenanceMessage;

  usePageView();

  return (
    <>
      <Seo title="Maintenance" />
      <Box
        component="main"
        sx={{
          alignItems: "center",
          display: "flex",
          flexGrow: 1,
          py: "80px",
        }}
      >
        <Container maxWidth="lg">
          <Typography
            align="center"
            style={{
              fontFamily: "tmwCustomFont",
              fontSize: !mdUp ? 55 : 45,
              color: "black",
              letterSpacing: 3,
              userSelect: "none",
              textTransform: "uppercase",
            }}
          >
            {maintenanceTitle}
          </Typography>
          <Typography
            align="center"
            style={{
              fontFamily: "tmwCustomFont",
              fontSize: !mdUp ? 15 : 12,
              color: "black",
              letterSpacing: 3,
              userSelect: "none",
              textTransform: "uppercase",
            }}
          >
            {maintenanceMessage}
          </Typography>
        </Container>
      </Box>
    </>
  );
};

export default MaintenancePage;
