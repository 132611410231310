import { useEffect, useRef, useState } from "react";
import {
  TextField,
  Button,
  Link,
  Typography,
  Box,
  Divider,
  Stack,
  Switch,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Tooltip,
  Alert,
  Select,
} from "@mui/material";
import { RouterLink } from "src/components/router-link";
import CustomCalendarIcon from "src/icons/tmw-ui/tmw-Calendar-Symbol-Option-2.png";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { usePathname } from "src/hooks/use-pathname";
// Utils
import OutsideClickHandler from "react-outside-click-handler";
import { style } from "@mui/system";
import { CloseOutlined } from "@mui/icons-material";

export const CustomTextField = (props) => {
  return (
    <Stack style={props?.style}>
      <span
        style={{
          fontFamily: "tmwCustomFont",
          fontSize: props.title ? 13 : 11,
          color: "black",
          letterSpacing: 3,
          fontWeight: props.title ? 600 : 500,
          userSelect: "none",
          textTransform: "uppercase",
          ...props?.labelStyle,
        }}
      >
        {props.label}
      </span>
      <TextField
        sx={{
          fontFamily: "tmwCustomFont",
          "& .MuiInput-underline:before": {
            borderBottom: props?.greyBar
              ? "1px solid grey"
              : "1px solid #E6E6E6",
          },
          "& .MuiInput-underline:after": {
            borderBottom: "2px solid black",
          },
          "& .MuiInputBase-input": {
            fontFamily: "tmwCustomFont",
          },
          "& .css-193nsx5-MuiTypography-root": {
            color: "black",
          },
          padding: "10px 0",
          ...props?.textFieldStyle,
        }}
        variant="standard"
        error={props?.error || !!(props?.touched && props?.errors)}
        fullWidth
        helperText={props?.helperText || (props?.touched && props?.errors)}
        name={props.name}
        onChange={props?.handleChange}
        type={props.type}
        value={props?.value}
        rows={props?.rows}
        required={props?.required}
        select={props?.select}
        onBlur={props?.onBlur}
        {...props.inputProps}
        placeholder={props?.calendar && "dd/mm/yyyy"}
        InputProps={{
          startAdornment: props?.dollarPercentage ? (
            <InputAdornment position="start">$</InputAdornment>
          ) : props?.gbpPercentage ? (
            <InputAdornment position="start">£</InputAdornment>
          ) : props?.yenPercentage ? (
            <InputAdornment position="start">¥</InputAdornment>
          ) : props?.percentage ? (
            <InputAdornment position="start">%</InputAdornment>
          ) : null,
          endAdornment: props?.calendar && (
            <InputAdornment position="end">
              {props?.clearable && (
                <CloseOutlined
                  fontSize="18"
                  onClick={props?.clearableClick}
                  style={{ margin: "0 10px 0 -25px", cursor: "pointer" }}
                />
              )}
              <img
                src={CustomCalendarIcon}
                style={{ width: "15px", borderRadius: "3px" }}
              />
            </InputAdornment>
          ),
        }}
        SelectProps={{
          multiple: props?.multiple,
        }}
        multiline={props?.multiline}
        minRows={props?.minRows}
        disabled={props?.disabled}
      >
        {props?.children}
      </TextField>
    </Stack>
  );
};

export const CustomButton = (props) => {
  const [hoveredButton, setHoveredButton] = useState(false);
  const smallButtonStyle = props.smallButton ? { height: "28px" } : {};

  return (
    <>
      <Button
        onMouseEnter={
          props?.onMouseEnter
            ? props?.onMouseEnter
            : () => setHoveredButton(true)
        }
        onMouseLeave={
          props?.onMouseLeave
            ? props?.onMouseLeave
            : () => setHoveredButton(false)
        }
        disableRipple
        onClick={props.onClick}
        sx={{
          fontFamily: "tmwCustomFont",
          width: props.smallButton
            ? "80px"
            : props.fullSize
            ? "100%"
            : "inherit",
          borderRadius: "25px",
          border: props.redButton
            ? "1px solid red"
            : props.greenButton
            ? "1px solid green"
            : props.blackButton
            ? "1px solid black"
            : "1px solid #E6E6E6",
          backgroundColor:
            props.filled &&
            (props.redButton
              ? "red"
              : props.greenButton
              ? "green"
              : props.blackButton
              ? "black"
              : "transparent"),
          "&:hover": {
            backgroundColor: props.redButton
              ? "red"
              : props.greenButton
              ? "green"
              : props.blackButton
              ? "black"
              : props.bookingSubmit
              ? "#7CAF00"
              : props.submitButton
              ? "white"
              : "#E6E6E6",
            borderColor: props.redButton
              ? "red"
              : props.greenButton
              ? "green"
              : props.blackButton
              ? "black"
              : props.bookingSubmit
              ? "#7CAF00"
              : props.submitButton
              ? "orange"
              : "#E6E6E6",
          },
          textTransform: "uppercase",
          ...props?.style,
          ...smallButtonStyle,
        }}
        disabled={props.disabled}
        type={props?.type}
        component={props?.component}
        href={props?.href}
        variant="outlined"
      >
        <span
          style={{
            color:
              hoveredButton && props?.bookingSubmit
                ? "white"
                : props?.bookingSubmit
                ? "#7CAF00"
                : !hoveredButton && props?.logoutButton
                ? "red"
                : hoveredButton && props?.logoutButton
                ? "white"
                : props?.blackText
                ? "black"
                : !props.smallButton
                ? "black"
                : hoveredButton || props?.filled
                ? "white"
                : props.redButton
                ? "#ee5555"
                : props.greenButton
                ? "green"
                : "black",
            transition: "ease-out 0.15s",
            opacity: props.bookingSubmit ? "" : props?.disabled ? "30%" : "",
            letterSpacing: 2,
            fontSize: props?.fontSize
              ? props.fontSize
              : props.smallButton
              ? 8
              : 11,
            userSelect: "none",
            fontWeight: 400,
            display: "flex",
            alignItems: "center",
            ...props?.labelStyle,
          }}
        >
          {props.label}
          {props?.loader}
        </span>
      </Button>
    </>
  );
};

export const CustomTypography = (props) => {
  return (
    <Typography
      style={{
        fontFamily: "tmwCustomFont",
        textTransform: "uppercase",
        letterSpacing: 2,
        fontSize: props?.title ? 14 : 11,
        fontWeight: props?.title ? "600" : "300",
        ...props?.style,
      }}
      variant="body"
    >
      {props.message}
    </Typography>
  );
};

export const CustomDropdown = ({
  buttons,
  label,
  style,
  dropdownStyle,
  router,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const submenuRef = useRef(null);
  const buttonRef = useRef(null);
  const open = Boolean(anchorEl);
  const pathname = usePathname();

  // Function to position the submenu exactly where the button is
  const updateSubmenuPosition = () => {
    if (submenuRef.current && buttonRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const submenu = submenuRef.current;

      // Position submenu exactly over the child button
      submenu.style.top = `${buttonRect.bottom}px`;
      submenu.style.left = `${buttonRect.left}px`;
    }
  };

  // Update submenu position on scroll and resize
  useEffect(() => {
    if (open) {
      updateSubmenuPosition();
      window.addEventListener("scroll", updateSubmenuPosition, true);
    }

    return () => {
      window.removeEventListener("scroll", updateSubmenuPosition, true);
    };
  }, [open]);

  const toggleDrawer = (event) => {
    if (open) {
      setAnchorEl(null);
    } else {
      updateSubmenuPosition();
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const CustomMenuButtonStyle = {
    backgroundColor: "transparent",
    fontFamily: "tmwCustomFont",
    width: "100%",
    padding: "5px 20px",
    letterSpacing: 2,
    fontSize: 11,
    fontWeight: 400,
    userSelect: "none",
    cursor: "pointer",
    justifyContent: "flex-start",
    textTransform: "uppercase",
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setAnchorEl(null)}>
      <Stack style={{ position: "relative", zIndex: "100" }}>
        <Button
          ref={buttonRef}
          disableRipple
          sx={{
            fontFamily: "tmwCustomFont",
            background: open ? "#E6E6E6" : "white",
            border: "1px solid #E6E6E6",
            borderRadius: !open ? "25px" : "25px 25px 0 0",
            "&:hover": {
              backgroundColor: "#E6E6E6",
              borderColor: "#E6E6E6",
            },
            height: "100%",
            width: "180px",
            ...style,
          }}
          variant="outlined"
          onClick={toggleDrawer}
        >
          <span
            style={{
              color: "black",
              letterSpacing: 3,
              fontSize: 11,
              fontWeight: 400,
              userSelect: "none",
              padding: "2px",
            }}
          >
            {label}
          </span>
        </Button>
        {open && (
          <Stack
            ref={submenuRef}
            id="custom-menu"
            onClick={handleClose}
            style={{
              position: "fixed",
              fontFamily: "tmwCustomFont",
              background: "white",
              border: "1px solid #E6E6E6",
              borderTop: "none",
              width: "180px",
              zIndex: 1000,
              borderRadius: "0 0 25px 25px",
              display: "flex",
              alignItems: "center",
              padding: "18px 2px",
              transition: "opacity 1s",
              top: 0,
              left: 0,
              ...dropdownStyle,
            }}
          >
            {buttons?.map((button, index) => {
              const checkPath = !!(button.label && pathname);
              const partialMatch = checkPath
                ? pathname.includes(button.id)
                : false;

              if (button.type === "divider") {
                return (
                  <Stack key={`divider-${index}`} padding="10px 0" width="90%">
                    <CustomDivider />
                  </Stack>
                );
              }
              return (
                <Button
                  key={`${button.label}-id`}
                  style={CustomMenuButtonStyle}
                  sx={{
                    color: router && partialMatch ? "#BEBEBE" : "black",
                    "&:hover": {
                      color: "#BEBEBE",
                    },
                  }}
                  onClick={button.handler}
                  component={RouterLink}
                  href={button.href}
                >
                  {button.label}
                </Button>
              );
            })}
          </Stack>
        )}
      </Stack>
    </OutsideClickHandler>
  );
};

export const CustomRouterLink = (props) => {
  return (
    <Link
      component={props.component}
      href={props.href}
      style={{ textDecoration: "none" }}
      sx={{
        borderBottom: "2px solid white",
        "&:hover": { borderBottom: "2px solid #333" },
      }}
    >
      <Typography
        style={{
          fontFamily: "tmwCustomFont",
          color: "black",
          letterSpacing: 3,
          fontSize: 12,
          fontWeight: 500,
          userSelect: "none",
          textTransform: "uppercase",
        }}
      >
        {props.label}
      </Typography>
    </Link>
  );
};

export const CustomDivider = ({ small, fullWidth, customWidth, sx }) => {
  return (
    <Box style={{ display: "flex", justifyContent: "center" }}>
      <Divider
        sx={{
          width: customWidth
            ? customWidth
            : small
            ? "85%"
            : fullWidth
            ? "100%"
            : "95%",
          borderColor: "#E6E6E6",
          ...sx,
        }}
      ></Divider>
    </Box>
  );
};

export const CustomOrDivider = () => {
  return (
    <Box
      style={{
        padding: "0 10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <span
        style={{
          fontFamily: "tmwCustomFont",
          color: "black",
          letterSpacing: 2,
          fontSize: 13,
          fontWeight: 500,
          userSelect: "none",
        }}
      >
        OR
      </span>
      <Divider
        sx={{
          width: "90%",
          borderColor: "#E6E6E6",
        }}
      ></Divider>
    </Box>
  );
};

export const DividerTitle = ({ title }) => {
  return (
    <Stack style={{ width: "100%" }}>
      <span
        style={{
          fontFamily: "tmwCustomFont",
          fontSize: 13,
          color: "black",
          letterSpacing: 3,
          fontWeight: 600,
          userSelect: "none",
          textTransform: "uppercase",
          marginBottom: "10px",
        }}
      >
        {title}
      </span>
      <CustomDivider fullWidth />
    </Stack>
  );
};

export const CustomToggle = (props) => {
  return (
    <Stack>
      <Stack spacing={1}>
        <Typography
          style={{
            fontFamily: "tmwCustomFont",
            color: props?.disabled ? "#BEBEBE" : "black",
            letterSpacing: 3,
            fontSize: 11,
            fontWeight: 600,
            userSelect: "none",
            textTransform: "uppercase",
            paddingBottom: "20px",
          }}
          variant="subtitle1"
        >
          {props.label}
        </Typography>
        <Switch
          {...props}
          sx={{
            width: 70,
            height: 36,
            padding: 0,
            "& .MuiSwitch-switchBase": {
              padding: 0,
              margin: "4px",
              transitionDuration: "300ms",
              color: "#E6E6E6",
              "&.Mui-checked": {
                transform: "translateX(33.5px)",
                color: "black",
                "& + .MuiSwitch-track": {
                  backgroundColor: "white",
                  opacity: 1,
                },
              },
              "&.Mui-disabled .MuiSwitch-thumb": {
                color: "#E6E6E6",
              },
              "&.Mui-disabled+.MuiSwitch-track": {
                opacity: 1,
              },
            },
            "& .MuiSwitch-thumb": {
              boxSizing: "border-box",
              width: 28,
              height: 28,
              transitionDuration: "100ms",
            },
            "& .MuiSwitch-track": {
              borderRadius: "25px",
              border: "1px solid #E6E6E6",
              backgroundColor: "white",
              opacity: 1,
            },
          }}
        />
      </Stack>
    </Stack>
  );
};

export const CustomFormControl = (props) => {
  return (
    <FormControlLabel
      style={{
        fontFamily: "tmwCustomFont",
        fontSize: 10,
        color: "black",
        letterSpacing: 1,
        fontWeight: 400,
        userSelect: "none",
        textTransform: "uppercase",
      }}
      control={<Checkbox />}
      {...props}
    />
  );
};

export const CustomTooltip = (props) => {
  return (
    <Tooltip
      PopperProps={{
        container: document.fullscreenElement ?? document.body,
      }}
      title={
        <span
          style={{
            fontFamily: "tmwCustomFont",
            letterSpacing: 1,
            fontSize: 10,
          }}
        >
          {props?.title}
        </span>
      }
      style={{ ...props?.style }}
    >
      {props?.children}
    </Tooltip>
  );
};

export const CustomUnsavedChangesAlert = (props) => {
  return (
    <Alert
      variant="filled"
      severity="warning"
      style={{
        display: "flex",
        alignItems: "center",
        background: "orange",
        border: "1px solid orange",
        borderRadius: "25px",
        fontFamily: "tmwCustomFont",
        letterSpacing: 2,
        textTransform: "uppercase",
        fontSize: 11,
        color: "black",
        marginLeft: "auto",
        maxWidth: 400,
      }}
    >
      Unsaved changes!
    </Alert>
  );
};

export const CustomSelect = (props) => {
  return (
    <Select
      id={props.id}
      sx={{
        fontFamily: "tmwCustomFont",
        letterSpacing: 2.5,
        textTransform: "uppercase",
        "&. MuiInputBase-input.MuiInput-input": {
          background: "white",
        },
        "& .MuiSelect-select": {
          fontSize: 11,
        },
        "& .MuiSelect-select:hover": {
          borderBottom: "none",
        },
        "&:before": {
          borderBottom: "1px solid #E6E6E6",
        },
        "&:after": {
          borderBottom: "2px solid black",
        },
      }}
      value={props?.value}
      onChange={props?.onChange}
      variant="standard"
      disabled={props?.disabled}
      style={{ width: props?.width, fontSize: "10px" }}
      IconComponent={() => (
        <ArrowDropDownIcon
          style={{
            cursor: "default",
            color: "black",
            transform: "scale(1, 1.7)",
          }}
        />
      )}
      SelectDisplayProps={{
        style: {
          background: "white",
        },
      }}
      MenuProps={{
        sx: {
          "& .MuiPaper-root": {
            transition: "transform 120ms !important",
          },
        },
        PaperProps: {
          sx: {
            transition: "none",
            boxShadow: "none",
            marginLeft: "-15px",
            borderRadius: 0,
            "& .MuiMenuItem-root": {
              fontFamily: "tmwCustomFont",
              letterSpacing: 2.5,
              textTransform: "uppercase",
              fontSize: 11,
              display: "flex",
              justifyContent: "flex-start",
            },
            "& .MuiMenuItem-root:hover": {
              background: "none",
            },
            "& .Mui-selected": {
              background: "white !important",
            },
            "& .Mui-selected:hover": {
              background: "white !important",
            },
          },
        },
      }}
    >
      {props?.children}
    </Select>
  );
};
