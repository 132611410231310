import { bookingProcessApi } from "src/api/booking-process";
import { slice } from "src/slices/booking-process";

const getStoreName = (userId) => async (dispatch) => {
  try {
    dispatch(slice.actions.setStoreNameLoading(true));
    const response = await bookingProcessApi
      .verifyCustomerId(userId)
      .catch(() => {
        dispatch(slice.actions.setStoreName(""));
      });
    dispatch(slice.actions.setStoreName(response));
    dispatch(slice.actions.setStoreNameLoading(false));
  } catch (err) {
    console.log(err);
  }
};

const getActiveAndTargetBrands =
  (userId, salesCampaignId) => async (dispatch) => {
    try {
      dispatch(slice.actions.activeBrandsLoading(true));
      const response1 = await bookingProcessApi.getCustomerActiveBrands(
        userId,
        salesCampaignId
      );
      dispatch(slice.actions.activeBrandsReceived(response1));
      dispatch(slice.actions.activeBrandsLoading(false));
      dispatch(slice.actions.targetBrandsLoading(true));
      const response2 = await bookingProcessApi.getCustomerTargetBrands(
        userId,
        salesCampaignId
      );
      dispatch(slice.actions.targetBrandsReceived(response2));
      dispatch(slice.actions.targetBrandsLoading(false));
    } catch (err) {
      dispatch(
        slice.actions.setHubspotError(
          "We apologize, but we encountered a problem while fetching the brands. Please try again later, thank you!"
        )
      );
    }
  };

const setSelectedBrands = (selectedBrands) => async (dispatch) => {
  dispatch(slice.actions.setSelectedBrands(selectedBrands));
};

const setNotes = (notes) => async (dispatch) => {
  dispatch(slice.actions.setNotes(notes));
};

const setSkippedBrands = (brandNames) => async (dispatch) => {
  dispatch(slice.actions.setSkippedBrands(brandNames));
};

const getBrandAvailabilities =
  (selectedBrands, salesCampaignId) => async (dispatch) => {
    try {
      const response = await bookingProcessApi.getBrandAvailabilities(
        selectedBrands,
        salesCampaignId
      );
      dispatch(slice.actions.setBrandAvailabilities(response));
    } catch (err) {
      console.log(err);
    }
  };

const getCusNumbers = (userId, salesCampaignId) => async (dispatch) => {
  try {
    const response = await bookingProcessApi.getCusNumbers(
      userId,
      salesCampaignId
    );
    dispatch(slice.actions.setCusNumbers(response));
  } catch (err) {
    dispatch(
      slice.actions.setHubspotError(
        "We apologize, but we encountered a problem while fetching the brands. Please try again later, thank you!"
      )
    );
  }
};

const getDeals = (userId, properties) => async (dispatch) => {
  const response = await bookingProcessApi.getDeals(userId, properties);
};

const getTimeSlots = (userId, properties) => async (dispatch) => {
  const response = await bookingProcessApi.getTimeSlots(userId, properties);
  dispatch(slice.actions.setTimeSlots(response.timeSlots));
};

const getTimezoneOffset = (userId, location, date) => async (dispatch) => {
  const response = await bookingProcessApi.getTimezoneOffset(
    userId,
    location,
    date
  );
  dispatch(slice.actions.setTimezoneOffset(response.timezoneOffset));
};

const getAllTimezones = (userId, date) => async (dispatch) => {
  const response = await bookingProcessApi.getAllTimezones(userId, date);
  dispatch(slice.actions.setTimezones(response));
};

const createBookings =
  (
    userId,
    bookings,
    cusNumbers,
    categories,
    notes,
    salesCampaignId,
    skippedBrands,
    adminId
  ) =>
  async (dispatch) => {
    try {
      const response = await bookingProcessApi.createBookings(
        salesCampaignId,
        userId,
        bookings,
        cusNumbers,
        categories,
        notes,
        adminId
      );
      dispatch(slice.actions.setBookings(response));

      await bookingProcessApi.updateDealsOfSkippedBrands(
        userId,
        salesCampaignId,
        skippedBrands
      );
    } catch (err) {
      dispatch(slice.actions.setBookingError(err.error.message));
    }
  };

const getCategories = (userId, salesCampaignId) => async (dispatch) => {
  try {
    const response = await bookingProcessApi.getCategories(
      userId,
      salesCampaignId
    );
    dispatch(slice.actions.setCategories(response));
  } catch (err) {
    dispatch(
      slice.actions.setHubspotError(
        "We apologize, but we encountered a problem while fetching the brands. Please try again later, thank you!"
      )
    );
  }
};

const verifySalesCampaign = (userId, salesCampaignId) => async (dispatch) => {
  try {
    const response = await bookingProcessApi.verifySalesCampaign(
      userId,
      salesCampaignId
    );
    dispatch(slice.actions.setSalesCampaignFlag(response));
    dispatch(slice.actions.setSalesCampaignFlagLoading(false));
  } catch (err) {
    console.log(err);
  }
};

const getBookingLinks = (userId) => async (dispatch) => {
  try {
    const response = await bookingProcessApi.getBookingLinks(userId);
    dispatch(slice.actions.setBookingLinks(response));
  } catch (err) {
    console.log(err);
  }
};

const getContactInfo = (userId) => async (dispatch) => {
  try {
    const response = await bookingProcessApi.getContactInfo(userId);
    dispatch(slice.actions.setContactInfo(response));
  } catch (err) {
    console.log(err);
  }
};

export const thunks = {
  getStoreName,
  getActiveAndTargetBrands,
  setSelectedBrands,
  setNotes,
  setSkippedBrands,
  getBrandAvailabilities,
  getCusNumbers,
  getDeals,
  getTimeSlots,
  getTimezoneOffset,
  getAllTimezones,
  createBookings,
  getCategories,
  verifySalesCampaign,
  getBookingLinks,
  getContactInfo,
};
