import { createSlice } from "@reduxjs/toolkit";

const portalSettingsState = {
  portalSettings: {},
  portalSettingsLoading: false,
};

const reducers = {
  portalSettingsLoading(state) {
    if (!state.portalSettingsLoading) {
      state.portalSettingsLoading = true;
    }
  },
  portalSettingsReceived(state, action) {
    if (state.portalSettingsLoading) {
      state.portalSettingsLoading = false;
      state.portalSettings = action.payload;
    }
  },
};

export const slice = createSlice({
  name: "portal-settings",
  initialState: portalSettingsState,
  reducers,
});

export const { reducer } = slice;
