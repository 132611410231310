import axios from "axios";
const deployEnvironment = require("../../deployEnvironment.js");

const API = deployEnvironment;
const STORAGE_KEY = "accessToken";

class PortalSettingsApi {
  async getPortalSettings() {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const getPortalSettingsResponse = await axios.get(
      `${API}/api/portal-settings`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const portalSettings = getPortalSettingsResponse.data;

    return new Promise((resolve, reject) => {
      try {
        if (!portalSettings) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(portalSettings);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }
}

export const portalSettingsApi = new PortalSettingsApi();
