import { useCallback, useEffect, useState } from "react";
import { CustomTextField, CustomButton } from "src/components/inputs";
import { withBookingSystemGuard } from "src/hocs/with-role-based-guard";
import { Box, Stack } from "@mui/material";
import Error404Page from "src/pages/404";
// Auth
import { useAuth } from "src/hooks/use-auth";
import { bookingSystemTomorrowCrewPermissions } from "src/guards/role-based-guard";
// Redux Toolkit
import { useDispatch, useSelector } from "src/store";
import { thunks } from "src/thunks/booking-system";
import { slice } from "src/slices/booking-system";

const SettingsPage = () => {
  // Redundant permission check
  const { user } = useAuth();
  const userPermissions = user?.permissions;

  const hasAccess = userPermissions?.find(
    (object) =>
      object.key === bookingSystemTomorrowCrewPermissions.admin ||
      object.key === bookingSystemTomorrowCrewPermissions.bookingSystemAdmin
  );

  if (!hasAccess) {
    return <Error404Page />;
  }

  const dispatch = useDispatch();
  const { brandPortfolioLink, showroomFlyersLink } = useSelector(
    (state) => state.bookingSystem
  );

  const [firstRender, setFirstRender] = useState(true); // Used to keep the submit button disabled on the first render
  const [brandPortfolioLinkValue, setBrandPortfolioLinkValue] = useState("");
  const [showroomFlyersLinkValue, setShowroomFlyersLinkValue] = useState("");

  useEffect(() => {
    dispatch(thunks.getBookingLinks(user.hashedId));
  }, []);

  // Populate links
  useEffect(() => {
    setBrandPortfolioLinkValue(brandPortfolioLink);
    setShowroomFlyersLinkValue(showroomFlyersLink);
    setFirstRender(false);
  }, [brandPortfolioLink, showroomFlyersLink]);

  const renderSaveButton = () => {
    const handleClick = () => {
      dispatch(
        thunks.setBookingLinks(
          user.hashedId,
          brandPortfolioLinkValue,
          showroomFlyersLinkValue
        )
      );
    };

    return (
      <Stack style={{ width: "175px", marginTop: "10px" }}>
        <CustomButton
          label="Save"
          onClick={handleClick}
          disabled={
            firstRender ||
            (brandPortfolioLinkValue === brandPortfolioLink &&
              showroomFlyersLinkValue === showroomFlyersLink)
          }
        />
      </Stack>
    );
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 7,
        px: 10,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Stack
        style={{
          display: "flex",
          alignItems: "flex-start",
          padding: "25px 0",
          gap: 30,
          width: "750px",
        }}
      >
        <Stack
          style={{
            display: "flex",
            flexDirection: "column",
            width: "125%",
          }}
        >
          <CustomTextField
            label="Brand Portfolio"
            value={brandPortfolioLinkValue}
            handleChange={(e) => {
              setBrandPortfolioLinkValue(e.target.value);
            }}
          />
        </Stack>
        <Stack
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <CustomTextField
            label="Showroom Flyers"
            value={showroomFlyersLinkValue}
            handleChange={(e) => {
              setShowroomFlyersLinkValue(e.target.value);
            }}
          />
        </Stack>
        {renderSaveButton()}
      </Stack>
    </Box>
  );
};

export default withBookingSystemGuard(SettingsPage);
