import PropTypes from "prop-types";
import { useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { MobileNav } from "../mobile-nav";
import { TopNav } from "./top-nav";
import { useMobileNav } from "./use-mobile-nav";
import MaintenancePage from "src/pages/maintenancePage";

const HorizontalLayoutRoot = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  overflow: "scroll",
});

const HorizontalLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  width: "100%",
});

export const HorizontalLayout = (props) => {
  const { children, navColor, sections } = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const mobileNav = useMobileNav();

  // Portal Settings
  const portalSettings = props?.portalSettings;

  const maintenanceFlag = portalSettings?.maintenanceMode;
  const maintenanceTitle = portalSettings?.maintenanceTitle;
  const maintenanceMessage = portalSettings?.maintenanceMessage;

  return (
    <>
      <TopNav
        color={navColor}
        onMobileNav={mobileNav.handleOpen}
        sections={sections}
      />
      {!lgUp && (
        <MobileNav
          color={navColor}
          onClose={mobileNav.handleClose}
          open={mobileNav.open}
          sections={sections}
        />
      )}
      {maintenanceFlag === false ? (
        <HorizontalLayoutRoot>
          <HorizontalLayoutContainer>{children}</HorizontalLayoutContainer>
        </HorizontalLayoutRoot>
      ) : (
        <MaintenancePage
          maintenanceTitle={maintenanceTitle}
          maintenanceMessage={maintenanceMessage}
        />
      )}
    </>
  );
};

HorizontalLayout.propTypes = {
  children: PropTypes.node,
  navColor: PropTypes.oneOf(["blend-in", "discreet", "evident"]),
  sections: PropTypes.array,
};
