import { createSlice } from "@reduxjs/toolkit";

const bookingSystemState = {
  allBookings: [],
  allBookingsLoading: false,
  newClientDropdownValues: [],
  newClientDropdownValuesLoading: false,
  salesCampaignDropdownValues: [],
  allLocations: [],
  exportLoading: false,
  skippedBookings: [],
  skippedBookingsLoading: false,

  brandPortfolioLink: "",
  showroomFlyersLink: "",
};

const reducers = {
  setAllBookings(state, action) {
    state.allBookings = action.payload;
  },
  setAllBookingsLoading(state, action) {
    state.allBookingsLoading = action.payload;
  },
  setNewClientDropdownValues(state, action) {
    state.newClientDropdownValues = action.payload;
  },
  setNewClientDropdownValuesLoading(state, action) {
    state.newClientDropdownValuesLoading = action.payload;
  },
  setSalesCampaignDropdownValues(state, action) {
    state.salesCampaignDropdownValues = action.payload;
  },
  setAllLocations(state, action) {
    state.allLocations = action.payload;
  },
  setExportLoading(state, action) {
    state.exportLoading = action.payload;
  },
  setSkippedBookings(state, action) {
    state.skippedBookings = action.payload;
  },
  setSkippedBookingsLoading(state, action) {
    state.skippedBookingsLoading = action.payload;
  },
  setBrandPortfolioLink(state, action) {
    state.brandPortfolioLink = action.payload;
  },
  setShowroomFlyersLink(state, action) {
    state.showroomFlyersLink = action.payload;
  },
};

export const slice = createSlice({
  name: "bookingSystem",
  initialState: bookingSystemState,
  reducers,
});

export const { reducer } = slice;
