import { useEffect, useState } from "react";
import { useGridApiContext } from "@mui/x-data-grid-pro";
// Hooks
import { useTranslation } from "react-i18next";
import { useRouter } from "src/hooks/use-router";
// Icons
import {
  CustomButton,
  CustomDivider,
  CustomTextField,
  CustomTooltip,
} from "src/components/inputs";
import {
  Autocomplete,
  Box,
  Button,
  ButtonBase,
  Chip,
  DialogActions,
  Popper,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import {
  Delete,
  SaveOutlined,
  Replay as RevertIcon,
  Error as ErrorIcon,
} from "@mui/icons-material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { useDispatch } from "src/store";
import { thunks } from "src/thunks/booking-system";
import { thunks as bookingProcessThunks } from "src/thunks/booking-process";
import { slice } from "src/slices/booking-system";

export const getStatusType = {
  draft: "Draft",
  pending: "Pending",
  approved: "Approved",
  approvingInProcess: "Approval in process",
  rejected: "Rejected",
};

export const dataGridStyle = {
  fontFamily: "tmwCustomFont",
  "& .MuiDataGrid-columnHeaders": {
    borderTop: "1px solid #E6E6E6",
    borderRadius: 0,
    borderBottom: "1px solid #E6E6E6",
  },
  "& .MuiInputBase-input": {
    fontSize: 12,
    fontWeight: 500,
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: 600,
  },
  "& .MuiDataGrid-columnHeader--emptyGroup": {
    outline: "none !important",
  },
  "& .MuiDataGrid-columnHeader:not(:first-of-type)": {
    borderLeft: "1px solid #E6E6E6",
    outline: "none",
  },
  "& .MuiDataGrid-cell": {
    borderLeft: "1px solid #E6E6E6",
    letterSpacing: 1,
    fontSize: 12,
    fontWeight: 400,
  },
  "& .MuiDataGrid-cell:last-child": {
    borderRight: "1px solid #E6E6E6",
  },
  "& .MuiDataGrid-row:not(:last-child)": {
    paddingTop: "1px",
    borderBottom: "1px solid #E6E6E6",
  },
  "& .MuiDataGrid-columnSeparator": {
    opacity: "0 !important",
  },
  "& .first-header-style": {
    borderLeft: "1px solid #E6E6E6 !important",
    outline: "none !important",
  },
  "& .last-header-style": {
    borderRight: "1px solid #E6E6E6 !important",
  },
  "& .header-style": {
    letterSpacing: 2,
    fontSize: 10,
    fontWeight: 600,
    textTransform: "uppercase",
  },
  "& .actions": {
    borderLeft: "1px solid #E6E6E6",
    display: "flex",
    justifyContent: "space-around",
  },
  "& .MuiDataGrid-footerContainer": {
    letterSpacing: 2,
    textTransform: "uppercase",
    fontSize: 11,
  },
  "& .MuiTablePagination-selectLabel": {
    fontSize: 11,
    letterSpacing: 1,
    fontFamily: "tmwCustomFont",
  },
  "& .MuiTablePagination-displayedRows": {
    fontSize: 11,
    letterSpacing: 1,
    fontFamily: "tmwCustomFont",
  },
  "& .MuiTablePagination-select": {
    fontSize: 11,
    letterSpacing: 1,
    fontFamily: "tmwCustomFont",
  },
  "& .MuiDataGrid-virtualScroller": {
    borderBottom: "1px solid #E6E6E6",
    letterSpacing: 2,
    fontSize: 10,
    fontWeight: 600,
    minHeight: "50px",
  },
  "& .column-groups": {
    marginTop: "0.5px",
  },
  "& .archived": {
    backgroundColor: "#FFEDED",
    "&:hover": {
      backgroundColor: "#F8EBEB",
    },
  },
};

const applyArchivedStyle = (archived) => {
  return archived ? "archived" : "";
};

// Status Cell custom Component
export const statusCellComponent = (status, inactive) => {
  return typeof status === "string" ? (
    <Box
      style={{ position: "relative", margin: "0 auto", marginRight: "-5px" }}
    >
      <Chip
        style={{
          height: "18px",
          width: "18px",
          borderRadius: "50%",
          marginRight: "10px",
          marginLeft: "5px",
          background: inactive
            ? "black"
            : status === getStatusType.draft
            ? "#F5911E"
            : status === getStatusType.pending
            ? "#FF0000"
            : status === getStatusType.approved
            ? "#39B349"
            : status === getStatusType.approvingInProcess
            ? "#54C3EA"
            : status === getStatusType.rejected
            ? "black"
            : "",
        }}
      />
    </Box>
  ) : (
    <Chip
      style={{
        height: "18px",
        width: "18px",
        borderRadius: "50%",
        marginRight: "10px",
        marginLeft: "5px",
      }}
      color={"default"}
    />
  );
};

// LOCATION EDIT

const handleLocationEditOnChange = (
  location,
  params,
  apiRef,
  changedValues
) => {
  apiRef.current.setEditCellValue({
    id: params.row.id,
    field: params.field,
    value: location,
  });

  const foundValueAndIndex = changedValues
    .map((element, index) => ({ element, index }))
    .find(
      (value) =>
        value.element.id === params.row.id && value.element.field === "location"
    );

  if (foundValueAndIndex?.index === undefined) {
    changedValues.push({
      id: params.row.id,
      field: params.field,
      value: location,
    });
  } else {
    changedValues[foundValueAndIndex.index].value = location;
  }

  apiRef.current.stopCellEditMode({
    id: params.row.id,
    field: params.field,
  });
};

const renderLocationEditCellComponent = (
  params,
  type,
  changedValues,
  allBookings,
  allLocations
) => {
  const apiRef = useGridApiContext();

  return (
    <Autocomplete
      id="autocomplete-edit-location-selection"
      name="autocomplete-edit-location-selection"
      disableClearable
      options={allLocations}
      value={params.row.location}
      onChange={(_, location) => {
        handleLocationEditOnChange(location, params, apiRef, changedValues);
      }}
      PopperComponent={({ style, ...props }) => (
        <Popper {...props} style={{ ...style, height: 0 }} />
      )}
      ListboxProps={{
        style: {
          fontFamily: "tmwCustomFont",
          fontSize: 13,
          borderRadius: 0,
          border: "none",
          boxShadow: "none",
        },
      }}
      style={{ width: "100%" }}
      renderInput={(inputParams) => {
        return (
          <Stack style={{ width: "100%", alignItems: "center" }}>
            <TextField
              {...inputParams}
              sx={{
                "& .MuiInput-underline:before": {
                  borderBottom: "1px solid #E6E6E6",
                },
                "& .MuiInput-underline:after": {
                  borderBottom: "2px solid #333",
                },
                "& .MuiInputBase-input": {
                  fontFamily: "tmwCustomFont",
                  paddingLeft: "10px",
                  textAlign: "center",
                },
                width: "100%",
              }}
            />
          </Stack>
        );
      }}
      // maxDate={formik.values.inboundDelivery.to}
    />
  );
};

// DATE EDIT

const handleDateEditOnChange = async (date, params, apiRef, changedValues) => {
  apiRef.current.setEditCellValue({
    id: params.row.id,
    field: params.field,
    value: date,
  });

  Promise.resolve(
    apiRef.current.startCellEditMode({ id: params.row.id, field: "time" })
  ).then(() => {
    console.log(apiRef.current);

    apiRef.current.setEditCellValue({
      id: params.row.id,
      field: "time",
      value: null,
    });

    apiRef.current.stopCellEditMode({
      id: params.row.id,
      field: "time",
    });
  });

  const foundValueAndIndex = changedValues
    .map((element, index) => ({ element, index }))
    .find(
      (value) =>
        value.element.id === params.row.id && value.element.field === "date"
    );

  if (foundValueAndIndex?.index === undefined) {
    changedValues.push({ id: params.row.id, field: params.field, value: date });
  } else {
    changedValues[foundValueAndIndex.index].value = date;
  }

  apiRef.current.stopCellEditMode({
    id: params.row.id,
    field: params.field,
  });
};

const renderDateEditCellComponent = (
  params,
  type,
  modalOpened,
  setModalOpened,
  changedValues
) => {
  const apiRef = useGridApiContext();

  const MyActionBar = ({ onAccept, onCancel, onClear, onSetToday }) => {
    return (
      <DialogActions>
        <Button
          onClick={() => {
            // setModalOpened(false);
            apiRef.current.stopCellEditMode({
              id: params.row.id,
              field: params.field,
            });
          }}
        >
          Cancel
        </Button>
        {/* <Button onClick={onAccept}>Save</Button> */}
      </DialogActions>
    );
  };

  return (
    <MobileDatePicker
      open={modalOpened}
      inputFormat="dd/MM/yyyy"
      onChange={(date) => {
        handleDateEditOnChange(date, params, apiRef, changedValues);
      }}
      onClose={() => {
        setModalOpened(false);
        apiRef.current.stopCellEditMode({
          id: params.row.id,
          field: params.field,
        });
      }}
      components={{
        ActionBar: MyActionBar,
      }}
      renderInput={(inputProps) => (
        <Stack style={{ width: "100%", alignItems: "center" }}>
          <CustomTextField
            style={{ width: "150px" }}
            textFieldStyle={{
              "& .MuiInputBase-input": {
                fontFamily: "tmwCustomFont",
                textAlign: "center",
              },
            }}
            inputProps={inputProps}
            clearable={params.row.date}
          />
        </Stack>
      )}
      value={params.row.date}
      // maxDate={formik.values.inboundDelivery.to}
    />
  );
};

// TIME EDIT

const handleTimeEditOnChange = (time, params, apiRef, changedValues) => {
  apiRef.current.setEditCellValue({
    id: params.row.id,
    field: params.field,
    value: time,
  });

  const foundValueAndIndex = changedValues
    .map((element, index) => ({ element, index }))
    .find(
      (value) =>
        value.element.id === params.row.id && value.element.field === "time"
    );

  if (foundValueAndIndex?.index === undefined) {
    changedValues.push({ id: params.row.id, field: params.field, value: time });
  } else {
    changedValues[foundValueAndIndex.index].value = time;
  }

  apiRef.current.stopCellEditMode({
    id: params.row.id,
    field: params.field,
  });
};

const renderTimeEditCellComponent = (
  params,
  type,
  changedValues,
  timeSlots
) => {
  const apiRef = useGridApiContext();

  return (
    <Autocomplete
      id="autocomplete-edit-time-selection"
      name="autocomplete-edit-time-selection"
      disableClearable
      options={
        timeSlots
          ?.map((timeSlot) =>
            timeSlot?.availableSlots > 0 ? timeSlot?.time : null
          )
          .filter(Boolean) || []
      }
      value={params.row.time}
      onChange={(_, time) => {
        handleTimeEditOnChange(time, params, apiRef, changedValues);
      }}
      PopperComponent={({ style, ...props }) => (
        <Popper {...props} style={{ ...style, height: 0 }} />
      )}
      ListboxProps={{
        style: {
          fontFamily: "tmwCustomFont",
          fontSize: 13,
          borderRadius: 0,
          border: "none",
          boxShadow: "none",
        },
      }}
      style={{ width: "100%" }}
      renderInput={(inputParams) => {
        return (
          <Stack style={{ width: "100%", alignItems: "center" }}>
            <TextField
              {...inputParams}
              sx={{
                "& .MuiInput-underline:before": {
                  borderBottom: "1px solid #E6E6E6",
                },
                "& .MuiInput-underline:after": {
                  borderBottom: "2px solid #333",
                },
                "& .MuiInputBase-input": {
                  fontFamily: "tmwCustomFont",
                  paddingLeft: "10px",
                },
                width: "100%",
              }}
            />
          </Stack>
        );
      }}
      // maxDate={formik.values.inboundDelivery.to}
    />
  );
};

// MEETING TYPE EDIT
const handleMeetingTypeEditOnChange = (
  meetingType,
  params,
  apiRef,
  changedValues
) => {
  apiRef.current.setEditCellValue({
    id: params.row.id,
    field: params.field,
    value: meetingType,
  });

  const foundValueAndIndex = changedValues
    .map((element, index) => ({ element, index }))
    .find(
      (value) =>
        value.element.id === params.row.id &&
        value.element.field === "meetingType"
    );

  if (foundValueAndIndex?.index === undefined) {
    changedValues.push({
      id: params.row.id,
      field: params.field,
      value: meetingType,
    });
  } else {
    changedValues[foundValueAndIndex.index].value = meetingType;
  }

  apiRef.current.stopCellEditMode({
    id: params.row.id,
    field: params.field,
  });
};

const renderMeetingTypeEditCellComponent = (params, type, changedValues) => {
  const apiRef = useGridApiContext();

  return (
    <Autocomplete
      id="autocomplete-edit-meetingType-selection"
      name="autocomplete-edit-meetingType-selection"
      disableClearable
      options={["Showroom", "Online"]}
      value={params.row.meetingType}
      onChange={(_, meetingType) => {
        handleMeetingTypeEditOnChange(
          meetingType,
          params,
          apiRef,
          changedValues
        );
      }}
      PopperComponent={({ style, ...props }) => (
        <Popper {...props} style={{ ...style, height: 0 }} />
      )}
      ListboxProps={{
        style: {
          fontFamily: "tmwCustomFont",
          fontSize: 13,
          borderRadius: 0,
          border: "none",
          boxShadow: "none",
        },
      }}
      style={{ width: "100%" }}
      renderInput={(inputParams) => {
        return (
          <Stack style={{ width: "100%", alignItems: "center" }}>
            <TextField
              {...inputParams}
              sx={{
                "& .MuiInput-underline:before": {
                  borderBottom: "1px solid #E6E6E6",
                },
                "& .MuiInput-underline:after": {
                  borderBottom: "2px solid #333",
                },
                "& .MuiInputBase-input": {
                  fontFamily: "tmwCustomFont",
                  paddingLeft: "10px",
                },
                width: "100%",
              }}
            />
          </Stack>
        );
      }}
      // maxDate={formik.values.inboundDelivery.to}
    />
  );
};

export const columnsDefinition = (
  userId,
  groupTypes,
  userGroup,
  modalOpened,
  setModalOpened,
  allBookings,
  allLocations,
  timeSlots,
  setDeleteAppointmentModalId,
  filters,
  setFilters
) => {
  const { t } = useTranslation();
  const router = useRouter();
  const dispatch = useDispatch();
  const [changedValues, setChangedValues] = useState([]);
  const [notClickedRow, setNotClickedRow] = useState(null);
  const [copiedToClipboard, setCopiedToClipboard] = useState("");

  const handleSaveRowChanges = (row) => {
    // Send request to the backend with the values and row id
    dispatch(
      thunks.updateBooking(
        userId,
        changedValues.filter((value) => value.id === row.id),
        row.appointmentId
      )
    );

    // Remove changed values (row) from the temp array
    setChangedValues(changedValues.filter((value) => value.id !== row.id));
  };

  const handleRevertRowChanges = (id, row) => {
    const updatedBookings = allBookings.map((booking) => {
      const foundChangedValues = changedValues.filter(
        (changedValue) => changedValue.id === booking.id
      );

      if (foundChangedValues.length) {
        const objectToReturn = { ...booking };

        foundChangedValues.map((foundChangedValue) => {
          if (foundChangedValue && foundChangedValue.id !== id) {
            if (foundChangedValue.field === "location") {
              objectToReturn.location = foundChangedValue.value;
            }

            if (foundChangedValue.field === "date") {
              objectToReturn.date = foundChangedValue.value;
            }

            if (foundChangedValue.field === "time") {
              objectToReturn.time = foundChangedValue.value;
            }

            if (foundChangedValue.field === "meetingType") {
              objectToReturn.meetingType = foundChangedValue.value;
            }
          }
        });

        return objectToReturn;
      }

      return booking;
    });

    // Persist filters
    const filteredUpdatedBookings = updatedBookings.filter((booking) => {
      return (
        (!filters.selectedBrand ||
          booking.brands?.includes(filters.selectedBrand)) &&
        (!filters.selectedCategory ||
          booking.categories?.includes(filters.selectedCategory)) &&
        (!filters.selectedSeason ||
          booking.season === filters.selectedSeason) &&
        (!filters.selectedLocation ||
          booking.location?.includes(filters.selectedLocation)) &&
        (!filters.selectedMeetingType ||
          booking.meetingType === filters.selectedMeetingType) &&
        (!filters.selectedSyncedWithHubspot ||
          booking.syncedWithHubspot ===
            (filters.selectedSyncedWithHubspot === "Yes")) &&
        (!filters.fromDate ||
          moment(filters.fromDate).format("YYYY/MM/DD") <=
            moment(booking.date).format("YYYY/MM/DD")) &&
        (!filters.toDate ||
          moment(filters.toDate).format("YYYY/MM/DD") >=
            moment(booking.date).format("YYYY/MM/DD"))
      );
    });

    setFilters((prevState) => ({
      ...prevState,
      ...filters,
      allBookingsRows: filteredUpdatedBookings,
    }));

    // Remove changed values (row) from the temp array
    setChangedValues(changedValues.filter((value) => value.id !== row.id));
  };

  const columns = [
    {
      field: "clientEmail",
      headerName: "Client",
      headerClassName: "first-header-style header-style",
      //   cellClassName: (param) => applyArchivedStyle(param.row.archived),
      // flex: 1,
      width: 325,
      editable: true,
      headerAlign: "center",
    },
    {
      field: "companies",
      headerName: "Companies",
      headerClassName: "header-style",
      cellClassName: (param) => applyArchivedStyle(param.row.archived),
      renderCell: (cell) => {
        const companies = cell.row.companies;

        return (
          <Stack style={{ width: "100%", height: "100%", padding: 5 }}>
            <Stack
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                alignItems: "flex-start",
                justifyContent: "center",
                padding: 5,
                gap: 5,
              }}
            >
              {companies?.map((company, index) => (
                <CustomButton
                  key={`all-bookings-company-button-${index}`}
                  onClick={() =>
                    router.push(
                      "/dashboard/client-management/companies/" +
                        company.id +
                        "/details"
                    )
                  }
                  label={
                    <Stack
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <CustomTooltip
                        title={
                          company.eTafStatus !== "Waiting for eTaf"
                            ? `eTaf ${
                                company.eTafStatus?.toLowerCase() || "No Status"
                              }`
                            : `${company.eTafStatus || "No Status"}`
                        }
                        key={"linesheet-status-tooltip-" + index}
                      >
                        {statusCellComponent(company.eTafStatus)}
                      </CustomTooltip>
                      <Stack
                        style={{
                          textAlign: "left",
                          width: "100%",
                          padding: "0 10px",
                        }}
                      >
                        {company.name}
                      </Stack>
                    </Stack>
                  }
                />
              ))}
            </Stack>
          </Stack>
        );
      },
      // flex: 1.65,
      width: 300,
      editable: false,
      headerAlign: "center",
    },
    {
      field: "brands",
      headerName: "Brands",
      headerClassName: "header-style",
      cellClassName: (param) => applyArchivedStyle(param.row.archived),
      renderCell: (cell) => {
        const brands = cell.row.brands || [];

        return (
          <Stack style={{ width: "100%", height: "100%", padding: 5 }}>
            <Stack
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                alignItems: "flex-start",
                justifyContent: "center",
                padding: 5,
                gap: 5,
              }}
            >
              {brands.map((brand, index) => {
                const formattedBrand = brand || "";
                return (
                  <CustomButton
                    key={`all-bookings-brand-${index}`}
                    label={formattedBrand}
                    style={{
                      cursor: "default",
                      "&:hover": {
                        backgroundColor: "transparent",
                        borderColor: "#E6E6E6",
                      },
                    }}
                  />
                );
              })}
            </Stack>
          </Stack>
        );
      },
      // flex: 1.4,
      width: 325,
      editable: false,
      headerAlign: "center",
    },
    {
      field: "categories",
      headerName: "Categories",
      headerClassName: "header-style",
      cellClassName: (param) => applyArchivedStyle(param.row.archived),
      renderCell: (cell) => {
        const categories = cell.row.categories || [];

        return (
          <Stack style={{ width: "100%", height: "100%", padding: 5 }}>
            <Stack
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                alignItems: "flex-start",
                justifyContent: "center",
                padding: 5,
                gap: 5,
              }}
            >
              {categories.map((category, index) => {
                const formattedCategory = category || "";
                return (
                  <CustomButton
                    key={`all-bookings-category-${index}`}
                    label={formattedCategory}
                    style={{
                      cursor: "default",
                      "&:hover": {
                        backgroundColor: "transparent",
                        borderColor: "#E6E6E6",
                      },
                    }}
                  />
                );
              })}
            </Stack>
          </Stack>
        );
      },
      // flex: 1,
      width: 250,
      editable: false,
      headerAlign: "center",
    },
    {
      field: "season",
      headerName: "Season",
      headerClassName: "header-style",
      cellClassName: (param) => applyArchivedStyle(param.row.archived),
      renderCell: (row) => {
        return (
          row.value && (
            <Stack style={{ width: "100%", alignItems: "center" }}>
              <CustomButton
                label={row.value}
                style={{
                  cursor: "default",
                  "&:hover": {
                    backgroundColor: "transparent",
                    borderColor: "#E6E6E6",
                  },
                }}
              />
            </Stack>
          )
        );
      },
      // flex: 0.75,
      width: 200,
      editable: false,
      headerAlign: "center",
    },
    {
      field: "location",
      headerName: "Location",
      headerClassName: "header-style",
      cellClassName: (param) => applyArchivedStyle(param.row.archived),
      renderCell: (row) => {
        return (
          <CustomTooltip title="Double click to edit the location">
            <Stack
              onClick={() => setNotClickedRow(null)}
              style={{
                width: "100%",
                textAlign: "center",
              }}
            >
              <CustomButton
                label={row.value}
                style={{ cursor: "pointer" }}
                labelStyle={{
                  color: changedValues.find(
                    (value) => value.id === row.id && value.field === "location"
                  )
                    ? "orange"
                    : "black",
                }}
              />
            </Stack>
          </CustomTooltip>
        );
      },
      // flex: 0.75,
      width: 275,
      editable: true,
      renderEditCell: (params) =>
        renderLocationEditCellComponent(
          params,
          "text",
          changedValues,
          allBookings,
          allLocations
        ),
      headerAlign: "center",
    },
    {
      field: "date",
      headerName: "Date",
      headerClassName: "header-style",
      cellClassName: (param) => applyArchivedStyle(param.row.archived),
      renderCell: (row) => {
        return (
          <Stack
            onClick={() => setNotClickedRow(null)}
            style={{
              width: "100%",
              alignItems: "center",
              color: changedValues.find(
                (value) => value.id === row.id && value.field === "date"
              )
                ? "orange"
                : "black",
              cursor: "pointer",
            }}
          >
            {moment(row.value).format("DD/MM/YYYY")}
          </Stack>
        );
      },
      // flex: 0.6,
      width: 200,
      editable: true,
      renderEditCell: (params) =>
        renderDateEditCellComponent(
          params,
          "text",
          modalOpened,
          setModalOpened,
          changedValues
        ),
      headerAlign: "center",
    },
    {
      field: "time",
      headerName: "Time",
      headerClassName: "header-style",
      cellClassName: (param) => applyArchivedStyle(param.row.archived),
      renderCell: (row, index) => {
        const value = row.value || "-";
        const timezoneOffset = row.row.timezoneOffset;
        const valueWithGMT = `${row.value} ${
          timezoneOffset
            ? `(GMT${
                timezoneOffset >= 0
                  ? `+${timezoneOffset}`
                  : `-${timezoneOffset * -1}`
              })`
            : ""
        }`;
        return (
          <CustomTooltip title={valueWithGMT} key={"time-tooltip-" + index}>
            <Stack
              onClick={() => {
                if (notClickedRow !== row.id) {
                  dispatch(
                    bookingProcessThunks.getTimeSlots(userId, {
                      brands: row.row.brands,
                      location: row.row.location,
                      date: moment(row.row.date).format("YYYY-MM-DD"),
                    })
                  );
                  setNotClickedRow(row.id);
                }
              }}
              style={{
                width: "100%",
                alignItems: "center",
                color: changedValues.find(
                  (value) => value.id === row.id && value.field === "time"
                )
                  ? "orange"
                  : "black",
                cursor: "pointer",
              }}
            >
              {value}
            </Stack>
          </CustomTooltip>
        );
      },
      // flex: 0.5,
      width: 200,
      editable: true,
      renderEditCell: (params) =>
        renderTimeEditCellComponent(params, "text", changedValues, timeSlots),
      headerAlign: "center",
    },
    {
      field: "meetingType",
      headerName: "Meeting Type",
      headerClassName: "header-style",
      cellClassName: (param) => applyArchivedStyle(param.row.archived),
      renderCell: (row) => {
        return (
          <CustomTooltip title="Double click to edit the meeting type">
            <Stack
              onClick={() => setNotClickedRow(null)}
              style={{
                width: "100%",
                textAlign: "center",
              }}
            >
              <CustomButton
                label={row.value}
                style={{ cursor: "pointer" }}
                labelStyle={{
                  color: changedValues.find(
                    (value) =>
                      value.id === row.id && value.field === "meetingType"
                  )
                    ? "orange"
                    : "black",
                }}
              />
            </Stack>
          </CustomTooltip>
        );
      },
      // flex: 0.75,
      width: 200,
      editable: true,
      renderEditCell: (params) =>
        renderMeetingTypeEditCellComponent(params, "text", changedValues),
      headerAlign: "center",
    },
    {
      field: "notes",
      headerName: "Notes",
      headerClassName: "header-style",
      cellClassName: (param) => applyArchivedStyle(param.row.archived),
      renderCell: (row) => {
        return (
          <Stack style={{ width: "100%", alignItems: "center" }}>
            {row.value}
          </Stack>
        );
      },
      // flex: 0.75,
      width: 200,
      editable: false,
      headerAlign: "center",
    },
    {
      field: "syncedWithHubspot",
      headerName: "Hubspot",
      headerClassName: "header-style",
      cellClassName: (param) => applyArchivedStyle(param.row.archived),
      renderCell: (cell) => {
        const HSDealIds = cell.row.HSDealIds;

        const renderChip = (dealSyncStatusType, dealSyncStatuses) => {
          const nrOfDeals = dealSyncStatuses.length;
          const nrOfGreenDeals = dealSyncStatuses.filter(
            (val) => val === true
          ).length;
          const nrOfRedDeals = dealSyncStatuses.filter(
            (val) => val === false
          ).length;

          return (
            <Chip
              style={{
                height: "18px",
                width: "18px",
                borderRadius: "50%",
                marginRight: "10px",
                marginLeft: "5px",
                background:
                  dealSyncStatusType === "mixed" &&
                  nrOfGreenDeals >= nrOfRedDeals
                    ? `linear-gradient(to right, green ${
                        (nrOfGreenDeals * 100) / nrOfDeals
                      }%, red ${(nrOfRedDeals * 100) / nrOfDeals}%)`
                    : dealSyncStatusType === "mixed" &&
                      nrOfGreenDeals < nrOfRedDeals
                    ? `linear-gradient(to left, red ${
                        (nrOfRedDeals * 100) / nrOfDeals
                      }%, green ${(nrOfGreenDeals * 100) / nrOfDeals}%)`
                    : dealSyncStatusType,
                cursor:
                  (dealSyncStatusType === "green" ||
                    dealSyncStatusType === "mixed") &&
                  "pointer",
              }}
            />
          );
        };

        const renderStatusCell = (HSDealIds) => {
          const dealIds = HSDealIds;
          const dealSyncStatuses = dealIds.map(
            (deal) => deal.syncedWithHubspot
          );
          const dealSyncStatusType = dealSyncStatuses.every(
            (val) => val === true
          )
            ? "green"
            : dealSyncStatuses.every((val) => val === false)
            ? "red"
            : "mixed";

          return (
            <CustomTooltip
              title={
                <Stack
                  style={{
                    fontFamily: "tmwCustomFont",
                    alignItems: "center",
                    userSelect: "none",
                  }}
                >
                  {dealIds.find(
                    (deal) => deal.HSDealId && deal.syncedWithHubspot
                  ) ? (
                    <span style={{ fontWeight: 600 }}>Hubspot deals:</span>
                  ) : (
                    <span style={{ fontWeight: 600 }}>Deals not synced</span>
                  )}
                  <span style={{ userSelect: "all" }}>
                    {" "}
                    {dealIds.map((deal, index) => {
                      return (
                        deal.HSDealId &&
                        deal.syncedWithHubspot && (
                          <Stack
                            key={index}
                            style={{
                              marginTop: "10px",
                              userSelect: "none",
                              alignItems: "center",
                            }}
                          >
                            <Stack style={{ flexDirection: "row", gap: 5 }}>
                              <span style={{ fontWeight: 600 }}>Brand: </span>
                              {deal.brand}
                            </Stack>
                            <Stack
                              style={{
                                flexDirection: "row",
                                gap: 5,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                navigator.clipboard.writeText(deal.HSDealId);
                                setCopiedToClipboard(deal.HSDealId);
                              }}
                            >
                              <span style={{ fontWeight: 600 }}>Deal Id: </span>
                              {deal.HSDealId}
                            </Stack>
                            <Stack
                              style={{
                                fontFamily: "tmwCustomFont",
                                fontStyle: "italic",
                                marginTop: "5px",
                                userSelect: "none",
                              }}
                            >
                              {copiedToClipboard !== deal.HSDealId ? (
                                "Click id to copy to clipboard"
                              ) : (
                                <span style={{ color: "#77bb77" }}>
                                  ID copied to clipboard
                                </span>
                              )}
                            </Stack>
                          </Stack>
                        )
                      );
                    })}
                  </span>
                </Stack>
              }
            >
              {renderChip(dealSyncStatusType, dealSyncStatuses)}
            </CustomTooltip>
          );
        };

        return (
          <Stack style={{ width: "100%", alignItems: "center" }}>
            {renderStatusCell(HSDealIds)}
          </Stack>
        );
      },
      // flex: 0.5,
      width: 125,
      editable: false,
      headerAlign: "center",
    },
    {
      field: "syncErrors",
      headerName: "Sync Error",
      headerClassName: "header-style",
      cellClassName: (param) => applyArchivedStyle(param.row.archived),
      renderCell: (cell) => {
        const syncErrors = cell.row.bookingSyncErrors;
        const hasSyncErrors = !!cell.row.bookingSyncErrors.filter(
          (syncError) => syncError.message
        ).length;

        const renderSyncErrors = () => {
          return (
            <Stack
              style={{
                display: "flex",
                alignItems: "center",
                userSelect: "none",
              }}
            >
              <span style={{ fontWeight: 600 }}>Deals sync errors:</span>
              {syncErrors.map((syncError, index) => {
                return (
                  <Stack
                    key={`booking-${cell.row.id}-sync-error-${index}`}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "20px",
                    }}
                  >
                    <Stack style={{ flexDirection: "row", gap: 5 }}>
                      <span style={{ fontWeight: 600 }}>Brand: </span>
                      {syncError.brand}
                    </Stack>
                    <Stack
                      style={{
                        flexDirection: "row",
                        gap: 5,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigator.clipboard.writeText(syncError.HSDealId);
                        setCopiedToClipboard(syncError.HSDealId);
                      }}
                    >
                      <span style={{ fontWeight: 600 }}>HSDealId: </span>
                      {syncError.HSDealId}
                    </Stack>
                    <Stack style={{ flexDirection: "row", gap: 5 }}>
                      <span style={{ color: "red" }}>{syncError.message}</span>
                    </Stack>
                    <Stack
                      style={{
                        fontFamily: "tmwCustomFont",
                        fontStyle: "italic",
                        marginTop: "5px",
                        userSelect: "none",
                      }}
                    >
                      {copiedToClipboard !== syncError.HSDealId ? (
                        "Click id to copy to clipboard"
                      ) : (
                        <span style={{ color: "#77bb77" }}>
                          ID copied to clipboard
                        </span>
                      )}
                    </Stack>
                  </Stack>
                );
              })}
            </Stack>
          );
        };

        return (
          hasSyncErrors && (
            <Stack style={{ width: "100%", alignItems: "center" }}>
              <CustomTooltip title={renderSyncErrors()}>
                <ErrorIcon
                  style={{ color: "red", width: 22, cursor: "pointer" }}
                />
              </CustomTooltip>
            </Stack>
          )
        );
      },
      width: 125,
      editable: false,
      headerAlign: "center",
    },
    {
      field: "createdAt",
      headerName: "Created At",
      headerClassName: "header-style",
      cellClassName: (param) => applyArchivedStyle(param.row.archived),
      renderCell: (row) => {
        return (
          <Stack style={{ width: "100%", alignItems: "center" }}>
            {moment(row.value).format("DD/MM/YYYY")}
          </Stack>
        );
      },
      // flex: 0.75,
      width: 150,
      editable: false,
      headerAlign: "center",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      headerClassName: "header-style last-header-style",
      cellClassName: (param) => applyArchivedStyle(param.row.archived),
      getActions: ({ id, row }) => {
        const disabled =
          !changedValues.filter((value) => value.id === row.id).length ||
          !row.time;
        const disabledClearItem = !changedValues.filter(
          (value) => value.id === row.id
        ).length;

        return [
          <CustomTooltip
            title="Save changes"
            key={"save-changes-tooltip-" + id}
          >
            <ButtonBase
              disabled={disabled}
              onClick={() => handleSaveRowChanges(row)}
            >
              <SaveOutlined style={{ color: disabled && "lightgrey" }} />
            </ButtonBase>
          </CustomTooltip>,
          <CustomTooltip
            title="Revert changes"
            key={"revert-changes-tooltip-" + id}
          >
            <ButtonBase
              disabled={disabledClearItem}
              onClick={() => handleRevertRowChanges(id, row)}
            >
              <RevertIcon style={{ color: disabledClearItem && "lightgrey" }} />
            </ButtonBase>
          </CustomTooltip>,
          <CustomTooltip title="Delete" key={"delete-row-tooltip-" + id}>
            <ButtonBase
              onClick={() => setDeleteAppointmentModalId(row.appointmentId)}
            >
              <Delete />
            </ButtonBase>
          </CustomTooltip>,
        ];
      },
      // flex: 0.5,
      width: 125,
    },
  ];

  return columns;
};
