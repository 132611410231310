import PropTypes from "prop-types";
import { withAuthGuard } from "src/hocs/with-auth-guard";
import { useSettings } from "src/hooks/use-settings";
import { useSections } from "./config";
import { HorizontalLayout } from "./horizontal-layout";
import { VerticalLayout } from "./vertical-layout";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "src/store";
// Redux Toolkit
import { portalSettingsThunks } from "src/thunks/portal-settings";

const STORAGE_KEY = "drawerOpened";

export const Layout = withAuthGuard((props) => {
  const dispatch = useDispatch();
  const settings = useSettings();
  const sections = useSections();

  const [drawerState, setDrawerState] = useState(
    window.localStorage.getItem(STORAGE_KEY) === "true"
  );
  const [drawerTransition, setDrawerTransition] = useState(true);

  const { portalSettings, portalSettingsLoading } = useSelector(
    (state) => state.portalSettings
  );

  useEffect(
    () => {
      // Fetch Portal Settings
      dispatch(portalSettingsThunks.getPortalSettings());
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [window.location.href]
  );

  const handleOpen = async () => {
    window.localStorage.setItem(STORAGE_KEY, true);
    setDrawerTransition(true);
    setDrawerState(true);
  };

  const handleClose = async () => {
    window.localStorage.setItem(STORAGE_KEY, false);
    setDrawerTransition(false);
    setDrawerState(false);
  };

  if (settings.layout === "horizontal") {
    return (
      <HorizontalLayout
        sections={sections}
        navColor={settings.navColor}
        portalSettings={portalSettings}
        portalLoading={portalSettingsLoading}
        {...props}
      />
    );
  }

  return (
    <VerticalLayout
      sections={sections}
      navColor={settings.navColor}
      drawerOpen={drawerState}
      handleOpen={handleOpen}
      handleClose={handleClose}
      drawerTransition={drawerTransition}
      portalSettings={portalSettings}
      portalLoading={portalSettingsLoading}
      {...props}
    />
  );
});

Layout.propTypes = {
  children: PropTypes.node,
};
