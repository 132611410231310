import { portalSettingsApi } from "src/api/portal-settings";
import { slice } from "src/slices/portal-settings";

// Get Portal Settings
const getPortalSettings = () => async (dispatch) => {
  dispatch(slice.actions.portalSettingsLoading());
  const response = await portalSettingsApi.getPortalSettings();
  dispatch(slice.actions.portalSettingsReceived(response));
};

export const portalSettingsThunks = {
  getPortalSettings,
};
